/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import nanoid from 'nanoid';
import AddQrCodeModal from "../../../views/pages/components/AddQrCodeModal"

require("../../../../src/assets/vendor/particleground/jquery.particleground.js")

class UserCardsHeader extends React.Component {
  constructor (props) {
    super(props);
    this.toggleAddQrModal = this.toggleAddQrModal.bind(this);
    this.notify = this.notify.bind(this);
  }  
  state = {
    addQrFormModal: false,
    shortCode: nanoid(5),
    qrData: this.props.qrdata,
    dataMonth: 1
  };

  notify = (type, title, message) => {
    this.props.notify(type, title, message);
    // let options = {
    //   place: "tr",
    //   message: (
    //     <div className="alert-text">
    //       <span className="alert-title" data-notify="title">
    //         {" "}
    //         {title}
    //       </span>
    //       <span data-notify="message">
    //         {message}
    //       </span>
    //     </div>
    //   ),
    //   type: type,
    //   icon: "ni ni-bell-55",
    //   autoDismiss: 7
    // };
    // this.refs.notificationAlert.notificationAlert(options);
  };

  toggleAddQrModal = (e, modalState) => {
    e.preventDefault();
    this.setState({
      shortCode: nanoid(5),
      addQrFormModal: !this.state.addQrFormModal
    });
    //console.log(this.state.shortCode);
  };

  componentWillReceiveProps(nextProps) {
    if(nextProps.qrdata !== undefined && nextProps.qrdata !== undefined) {
      let prevCodes = this.state.qrData === undefined ? [] : this.state.qrData;
      if(JSON.stringify(prevCodes) !== JSON.stringify(nextProps.qrdata))
        this.setState({qrData: nextProps.qrdata});
    }
  }

  componentWillMount() {
    this.props.monthChange(this.state.dataMonth);
  }

  render() {
    let totalTraffic = 0;
    let totalCountries = 0;
    let totalReferers = 0;
    if(this.state.qrData !== null && this.state.qrData !== undefined) {
      for (let i=0; i<this.state.qrData.length; i++) {
        let qr = this.state.qrData[i];
        totalTraffic += qr.item2.total_clicks;
        if(qr.item2.statsByCountry !== null)
          totalCountries += qr.item2.statsByCountry.length;
        if(qr.item2.statsByReferer !== null)
          totalReferers += qr.item2.statsByReferer.length;
      }
    }
    
    //console.log("Rendering User Card");
    return (
      <>
        <div id="usercardsheader" className="header bg-info pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    {this.props.name}
                  </h6>{" "}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <i className="fas fa-home" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        {this.props.parentName}
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      {this.props.name}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col className="text-right" lg="6" xs="5">
                  
                  {/* <Button
                    className="btn-neutral"
                    color="default"
                    href="#pablo"
                    //onClick={e => e.preventDefault()}
                    onClick={e => this.toggleAddQrModal(e, this.state.addQrFormModal)}
                    size="sm"
                  >
                    New
                  </Button> */}
                  
                  <UncontrolledDropdown>
                    <DropdownToggle caret color="secondary" size="sm">
                      Filter Dates
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={e => {e.preventDefault();this.props.monthChange(1)}}>
                        Last 1 Month
                      </DropdownItem>
                      <DropdownItem onClick={e => {e.preventDefault();this.props.monthChange(3)}}>
                        Last 3 Months
                      </DropdownItem>
                      <DropdownItem onClick={e => {e.preventDefault();this.props.monthChange(6)}}>
                        Last 6 Months
                      </DropdownItem>
                      {/* <DropdownItem divider />
                      <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                        Separated link
                      </DropdownItem> */}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <Button
                    //className="btn-neutral"
                    color="default"
                    //type="button"
                    href="#user"
                    size="sm"
                    onClick={e => this.toggleAddQrModal(e, this.state.addQrFormModal)}
                  >
                    Add QR Code
                  </Button>
                  <AddQrCodeModal isOpen={this.state.addQrFormModal} toggleModal={this.toggleAddQrModal} shortCode={this.state.shortCode} />
                  
                </Col>
              </Row>

              <Row>
                <Col md="4" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total traffic
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {totalTraffic}
                            
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                            <i className="ni ni-active-40" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        {/* <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "} */}
                        <span className="text-nowrap">Since {this.props.currMonth} month</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Countries
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {totalCountries}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                            <i className="fa fa-globe-americas" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        {/* <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "} */}
                        <span className="text-nowrap">Since {this.props.currMonth} month</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Referers
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">{totalReferers}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                            <i className="ni ni-money-coins" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        {/* <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "} */}
                        <span className="text-nowrap">Since {this.props.currMonth} month</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col md="6" xl="3">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total QR Codes
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            49,65%
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                            <i className="ni ni-chart-bar-32" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">Since {this.props.currMonth} month</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col> */}
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

UserCardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  notify: PropTypes.func,
  currMonth: PropTypes.number,
  monthChange: PropTypes.func
};

export default UserCardsHeader;
