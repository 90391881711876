import React from "react";
import ContentLoader from "react-content-loader";

export const qrCardLoader = () => (
    <ContentLoader
    height={480}
    width={284}
    speed={1}
    primaryColor="#61dafb"
    secondaryColor="#1a6498" 
    
  >
    <rect x="2" y="20" rx="0" ry="0" width="280" height="160" />
    <rect x="100" y="80" rx="0" ry="0" width="80" height="80" />
    <rect x="80" y="180" rx="3" ry="3" width="120" height="10" />
    <rect x="80" y="200" rx="3" ry="3" width="120" height="10" />
    <rect x="15" y="240" rx="3" ry="3" width="250" height="40" />
    <rect x="15" y="300" rx="3" ry="3" width="250" height="40" />
    <rect x="15" y="360" rx="3" ry="3" width="250" height="40" />
    <rect x="5" y="420" rx="3" ry="3" width="275" height="40" />
  </ContentLoader>
);

export const DashboardStatLoader = () => (
    <ContentLoader
    height={120}
    width={380}
    speed={1}
    primaryColor="#61dafb"
    secondaryColor="#1a6498"
    style={{ backgroundColor: "#61dafb", marginLeft: 15 }}
  >
    <circle r="35" cx="330" cy="50" />
    <rect x="15" y="15" rx="3" ry="3" width="150" height="20" />
    <rect x="15" y="45" rx="3" ry="3" width="150" height="30" />
    <rect x="15" y="100" rx="3" ry="3" width="200" height="10" />
  </ContentLoader>
)