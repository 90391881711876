/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

class Login extends React.Component {
  render() {
    let initParticle = window.location.pathname === "/";
    let pg = (
      <script type="text/javascript">
        if(window.location.pathname === "/") {
          document.addEventListener('DOMContentLoaded', function () {
            setTimeout(function() {
              if(document.getElementById('header-main-content') !== null)
            particleground(document.getElementById('header-main-content'), {
              dotColor: '#5cbdaa',
              lineColor: '#5cbdaa',
              density: 8000,
              minSpeedX: 0.5,
              maxSpeedX: 1.5,
              minSpeedY: 0.5,
              maxSpeedY: 1.5,
              particleRadius: 5,
              curvedLines: false,
              multicolor: ['#172b4d','#5e72e4','#11cdef','#2dce89','#f5365c','#fb6340']
            });
            // particleground(document.getElementById('sidebar-scrollbar'), {
            //   dotColor: '#5cbdaa',
            //   lineColor: '#5cbdaa',
            //   density: 5000,
            //   minSpeedX: 0.5,
            //   maxSpeedX: 1.5,
            //   minSpeedY: 0.5,
            //   maxSpeedY: 1.5,
            //   particleRadius: 5,
            //   curvedLines: false,
            //   multicolor: ['#172b4d','#5e72e4','#11cdef','#2dce89','#f5365c','#fb6340']
            // });
            // particleground(document.getElementById('admin-navbar'), {
            //   dotColor: '#5cbdaa',
            //   lineColor: '#5cbdaa',
            //   density: 6000,
            //   minSpeedX: 0.5,
            //   maxSpeedX: 1.5,
            //   minSpeedY: 0.5,
            //   maxSpeedY: 1.5,
            //   particleRadius: 5,
            //   curvedLines: false,
            //   multicolor: ['#172b4d','#5e72e4','#11cdef','#2dce89','#f5365c','#fb6340']
            // })
          }, 3000)
          })
        }
        </script>
      )
    return (
      <>
        <footer className="py-5" id="footer-main">
          <Container>
            <Row className="align-items-center justify-content-xl-between">
              <Col xl="6">
                <div className="copyright text-center text-lg-left text-muted">
                  © {new Date().getFullYear()}{" "}
                  <a
                    className="font-weight-bold ml-1 mr-2"
                    href="https://www.serveqr.com?ref=footer"
                    target="_blank"
                  >
                    Serve QR
                  </a> 
                  Made with <i className="fa fa-heart text-red"></i>
                </div>
              </Col>
              {/* <Col xl="6">
                <Nav className="nav-footer justify-content-center justify-content-xl-end">
                  <NavItem>
                    <NavLink
                      href="https://www.serveqr.com?ref=adpr-auth-footer"
                      target="_blank"
                    >
                      Creative TimA
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://www.creative-tim.com/presentation?ref=adpr-auth-footer"
                      target="_blank"
                    >
                      About Us
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="http://blog.creative-tim.com?ref=adpr-auth-footer"
                      target="_blank"
                    >
                      Blog
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://www.creative-tim.com/license?ref=adpr-auth-footer"
                      target="_blank"
                    >
                      License
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col> */}
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Login;
