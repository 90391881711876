/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from 'react-redux';
import { getQrCodeStats } from '../../../actions/qrcodeActions';
// reactstrap components
import {
  Container,
  Card,
  CardDeck,
  Col
} from "reactstrap";
// core components

// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import UserCardsHeader from "components/Headers/User/UserCardsHeader.jsx";
import QrCard from "../components/QrCard";
import DownloadQr from '../components/DownloadQr';
import { formatDate, addMonth } from '../../../utils/helper';

import { qrCardLoader } from "../../../utils/loaders";
class QrCodesCards extends React.Component {
  constructor() {
    super();
    this.state = {
      qrCodes: [],
      qrdata: [],
      downloadQrModal: false,
      selectedQrCode: {},
      endDate: new Date(),
      startDate: addMonth(new Date(), -1),
      filterStatsStartDate: addMonth(new Date(), -1),
      filterStatsEndDate: new Date(),
      dataMonth: 1
    }
    this.toggleDownloadQrModal = this.toggleDownloadQrModal.bind(this);
    this.notify = this.notify.bind(this);
    //this.handleDownloadQrModalChange = this.handleDownloadQrModalChange.bind(this);
  }

  notify = (type, title, message) => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  toggleDownloadQrModal = (e, modalState) => {
    e.preventDefault();
    this.setState({
      downloadQrModal: !this.state.downloadQrModal
    });
    //console.log("toggleDownloadQrModal");
  };

  getDataFromQrCode(params) {
    //console.log("received data", params);
    this.setState({
      selectedQrCode: params
    });
    //e.preventDefault();
    this.setState({
      downloadQrModal: !this.state.downloadQrModal
    });
  }

  updateDataFromDownloadQrCode(params) {
    //console.log("received data", params);
    this.setState({
      selectedQrCode: params
    });

  }

  handleDateChange = (elem) => {
    //elem.preventDefault();
    let name = elem.target.name;
    let value = new Date(elem.target.value);
    let fields = this.state;
    fields[name] = value;
    this.setState({ fields });
    //console.log(this.state);
  }

  handleMonthChange = (monthCount) => {
    let fields = this.state;
    fields["filterStatsEndDate"] = new Date();
    fields["filterStatsStartDate"] = addMonth(new Date(), -monthCount);
    this.setState({ fields, dataMonth: monthCount });
    this.getAllQrCodeStatsByDate();
  }

  getAllQrCodeStats() {

    let end_date = new Date();
    let start_date = addMonth(end_date, -1);
    let startDateStr = formatDate(start_date);
    let endDateStr = formatDate(end_date);
    let formData = new FormData();
    formData.append('startDate', startDateStr);
    formData.append('endDate', endDateStr);
    this.props.getQrCodeStats(formData);
  }

  getAllQrCodeStatsByDate = () => {
    //console.log(this.state);
    let end_date = this.state.filterStatsEndDate;
    let start_date = this.state.filterStatsStartDate;
    let startDateStr = formatDate(start_date);
    let endDateStr = formatDate(end_date);
    let formData = new FormData();
    formData.append('startDate', startDateStr);
    formData.append('endDate', endDateStr);
    this.props.getQrCodeStats(formData);
  }

  componentWillMount() {
    var rememberMe = localStorage["rememberMe"];
    if (rememberMe === true) {
      var token = localStorage["token"];
      var user = localStorage["user"];

      if (token === undefined || token === '' || user === undefined || user === '') {
        console.log("redirecting80");
        window.location = "/auth/login"
      }
    }
    else {
      token = sessionStorage["token"];
      user = sessionStorage["user"];

      if (token === undefined || token === '' || user === undefined || user === '') {
        console.log("redirecting");
        window.location = "/auth/login"
      }
    }

    //this.getAllQrCodeStats()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.qrcodestats !== undefined && nextProps.qrcodestats.data !== undefined) {
      let qrdata = [];
      let qrcodes = nextProps.qrcodestats.data.map(qrcode => {
        qrdata.push(qrcode);
        let code = qrcode.item1;
        let stats = qrcode.item2;
        // let qrData = {};
        // qrData.name = code.name.title + " " + code.name.first + " " + code.name.last;
        // qrData.city = code.location.city;
        // qrData.email = code.email;
        // qrData.url = code.picture.medium;
        //return qrData;
        return (
          <QrCard style={{ flex: 1 }} title={code.title} key={code.qrCodeId} stats={stats} description={code.tags} url={code.longUrl} downloadQrModal={this.toggleDownloadQrModal} data={code} qrData={this.getDataFromQrCode.bind(this)} notify={this.notify} />
        )
      });
      let prevCodes = this.state.qrCodesRender === undefined ? {} : this.state.qrCodesRender;
      if (JSON.stringify(prevCodes) !== JSON.stringify(qrcodes))
        this.setState({ qrCodesRender: qrcodes, qrdata: qrdata });
    }
  }

  //   shouldComponentUpdate(nextProps, nextState) {
  //     let qrCodes = this.state.qrCodesRender;
  //     let newQrCodes = nextState.qrCodesRender;    
  //     return JSON.stringify(qrCodes) !== JSON.stringify(newQrCodes);
  // }

  render() {
    //console.log(this.props.qrcodestats);
    // let qrcodes = {};
    // if(this.props.qrcodestats !== undefined && this.props.qrcodestats.data !== undefined) 
    // {qrcodes = this.props.qrcodestats.data.map(qrcode => {
    //   let code = qrcode.item1;
    //   let stats = qrcode.item2;
    //   return (
    //     <QrCard style={{flex: 1}} title={code.title} stats={stats} description={code.tags} url={code.longUrl} downloadQrModal={this.toggleDownloadQrModal} data={code} qrData={this.getDataFromQrCode.bind(this)}  notify={this.notify} />
    //   )
    // });
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <UserCardsHeader name="Cards" parentName="Components" notify={this.notify} qrdata={this.state.qrdata} monthChange={this.handleMonthChange} currMonth={this.state.dataMonth} />

        <Container className="mt-4" fluid>
          {/* <Form role="form">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-calendar-alt" />
                  </InputGroupText>
                  <UncontrolledDropdown group>
                  <DropdownToggle caret color="secondary">
                    Filter Dates
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={e => {e.preventDefault();this.handleMonthChange(1)}}>
                      Last 1 Month
                    </DropdownItem>
                    <DropdownItem onClick={e => {e.preventDefault();this.handleMonthChange(3)}}>
                      Last 3 Months
                    </DropdownItem>
                    <DropdownItem onClick={e => {e.preventDefault();this.handleMonthChange(6)}}>
                      Last 6 Months
                    </DropdownItem>
                    
                  </DropdownMenu>
                </UncontrolledDropdown>
                </InputGroupAddon>
                <label className="form-control">Start Date</label>
                <Input name="filterStatsStartDate" type="date" onChange={this.handleDateChange} />
                <label className="form-control">End Date</label>
                <Input name="filterStatsStartDate" type="date" onChange={this.handleDateChange} />
                <Button color="default" type="button" size="default"
                  onClick={this.getAllQrCodeStatsByDate}
                >
                  Filter Stats
                </Button>
                
              </InputGroup>
            </FormGroup>
          </Form> */}
          <CardDeck style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
            {/* {this.state.qrCodes} */}
            {this.state.qrCodesRender !== undefined && this.state.qrCodesRender.length >= 0 ?
              this.state.qrCodesRender : <>
                <Card className="card-profile" style={{ flex: 1, minWidth: '15%', maxWidth: '15%', minHeight: 480 }}>
                  <Col style={{ flexBasis: '280px', backgroundColor: '#1a6498' }}>
                    {qrCardLoader()}
                  </Col>
                </Card>
                <Card className="card-profile" style={{ flex: 1, minWidth: '15%', maxWidth: '15%', minHeight: 480 }}>
                  <Col style={{ flexBasis: '280px', backgroundColor: '#1a6498' }}>
                    {qrCardLoader()}
                  </Col>
                </Card>
                <Card className="card-profile" style={{ flex: 1, minWidth: '15%', maxWidth: '15%', minHeight: 480 }}>
                  <Col style={{ flexBasis: '280px', backgroundColor: '#1a6498' }}>
                    {qrCardLoader()}
                  </Col>
                </Card></>
            }

          </CardDeck>
          <DownloadQr isOpen={this.state.downloadQrModal} toggleModal={this.toggleDownloadQrModal} qrCodeData={this.state.selectedQrCode} qrData={this.updateDataFromDownloadQrCode.bind(this)} />
          {/* <Button
                  className="btn-neutral"
                  color="default"
                  //type="button"
                  href="#user"
                  size="sm"
                  onClick={e => this.toggleDownloadQrModal(e, this.state.downloadQrModal)}
                >
                  Add QR Code
          </Button> */}
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  //qrcodes: state.qrCodeReducer.items,
  qrcodestats: state.qrCodeReducer.stats
});

export default connect(mapStateToProps, { getQrCodeStats })(QrCodesCards);
