import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal
} from "reactstrap";
import PropTypes from "prop-types";
import QRCode from 'qrcode.react';

import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color';


class DownloadQr extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadQrModal: props.isOpen,
      fields: this.props.qrCodeData,
      displayFgColorPicker: false,
      displayBgColorPicker: false,
      color: {
        r: '241',
        g: '112',
        b: '19',
        a: '1',
      },
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    //this.state.fields = this.props.qrCodeData;
  }

  handleFgClick = () => {
    this.setState({ displayFgColorPicker: !this.state.displayFgColorPicker })
  };
  handleBgClick = () => {
    this.setState({ displayBgColorPicker: !this.state.displayBgColorPicker })
  };

  handleFgClose = () => {
    this.setState({ displayFgColorPicker: false })
  };
  handleBgClose = () => {
    this.setState({ displayBgColorPicker: false })
  };

  handleBgColorChange = (color) => {
    this.props.qrCodeData.bgColor = color.hex;
    //this.setState({ color: color.rgb })
  };
  handleFgColorChange = (color) => {
    this.props.qrCodeData.fgColor = color.hex;
    //this.setState({ color: color.rgb })
  };

  handleChange = (event) => {
    // console.log(this.props.qrCodeData);
    // console.log(event.target);
    //this.props.qrCodeData.onChange(event.target.value);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'size') {
      const data = this.props.qrCodeData;
      data.size = typeof (value) === "string" ? parseInt(value) : value;
      //data.renderAs = 'svg';
      //  this.props.qrCodeData.size = value;
      //  this.props.qrCodeData.renderAs = 'svg';
      this.props.qrData(data);
      //console.log("Changed value to", this.props.qrCodeData.size)
    }

    if (name === 'renderAs') {
      const data = this.props.qrCodeData;
      data.renderAs = value;
      this.props.qrData(data);
    }


    //this.props.qrCodeData = this.props.qrCodeData;
    //this.props.qrCodeData[name] = value;
    //fields[name] = value;
    // this.setState({
    //   fields: fields
    // });
  }

  toggleModal = state => {
    //console.log("Download QR");
    this.setState({
      [state]: !this.state[state]
    });
  };

  download = () => {
    const canvas = document.querySelector('.downPrvQrcode > canvas');
    var image = canvas.toDataURL("img/png", 1.0).replace("image/png", "image/octet-stream");
    //this.downloadRef.download = 'mypainting.png';
    var link = document.createElement('a');
    link.download = "my-image.png";
    link.href = image;
    link.click();
  }
  render() {

    const styles = reactCSS({
      'default': {
        fgColor: {
          width: '50px',
          height: '20px',
          borderRadius: '2px',
          background: this.props.qrCodeData.fgColor //`rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
        },
        bgColor: {
          width: '50px',
          height: '20px',
          borderRadius: '2px',
          background: this.props.qrCodeData.bgColor //`rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    const isDataAvailable = (this.props.qrCodeData != null && this.props.qrCodeData.value != null)


    //console.log(this.props.qrCodeData);
    return (

      <>

        {/* <Button
          color="primary"
          type="button"
          onClick={() => this.toggleModal("downloadQrModal")}
        >
          Edit and Download QR Code
        </Button> */}

        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.isOpen} toggle={this.props.toggleModal}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent pb-4">
                <div className="text-muted text-center mt-2 mb-3">
                  <label>Edit &amp; Download QR</label>
                </div>
                <div className="btn-wrapper text-center">
                  <div className="center header downPrvQrcode rounded-circle" style={{ maxWidth: 250, maxHeight: 250 }}>
                    {isDataAvailable ? (
                      <QRCode
                        value={this.props.qrCodeData.value}
                        size={this.props.qrCodeData.size}
                        fgColor={this.props.qrCodeData.fgColor}
                        bgColor={this.props.qrCodeData.bgColor}
                        level={this.props.qrCodeData.level}
                        renderAs={this.props.qrCodeData.renderAs}
                        includeMargin={this.props.qrCodeData.includeMargin}
                      />
                    ) : (<i className="fa fa-qrcode" style={{ fontSize: 48 }}></i>)
                    }
                  </div>
                </div>
              </CardHeader>
              <CardBody className="px-lg-4 py-lg-2">
                <div className="text-center text-muted mb-3">
                  <small>Please enter the required details</small>
                </div>
                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-link" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="URL" name="url" disabled required type="url" value={this.props.qrCodeData.value || ''} onChange={this.handleChange.bind(this)} />
                    </InputGroup>
                  </FormGroup>
                  {/* <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-globe-americas" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input type="select" value={this.props.qrCodeData.shortDomain} onChange={this.handleChange.bind(this)} name="shortDomain">
                            <option value="" disabled>Shorten URL using...</option>
                            <option value="fwd.to">fwd.to</option>
                          </Input>
                        </InputGroup>
                      </FormGroup> */}
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-qrcode" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="select" name="qrCodeCorrect" value={this.props.qrCodeData.level} onChange={this.handleChange.bind(this)}>
                        <option value="" disabled>QR Code Error Correction...</option>
                        <option value="l">L (Low)</option>
                        <option value="m">M (Medium)</option>
                        <option value="q">Q (Quartile)</option>
                        <option value="h">H (High)</option>
                      </Input>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-qrcode" style={{ backgroundColor: this.props.qrCodeData.bgColor, color: this.props.qrCodeData.fgColor }} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <label className="form-control">Foreground</label>
                      <div className="form-control">
                        <div style={styles.swatch} onClick={this.handleFgClick}>
                          <div style={styles.fgColor} />
                        </div>
                        {this.state.displayFgColorPicker ? <div style={styles.popover}>
                          <div style={styles.cover} onClick={this.handleFgClose} />
                          <SketchPicker color={this.props.fgColor} onChange={this.handleFgColorChange} />
                        </div> : null}
                      </div>
                      <label className="form-control">Background</label>
                      <div className="form-control">
                        <div style={styles.swatch} onClick={this.handleBgClick}>
                          <div style={styles.bgColor} />
                        </div>
                        {this.state.displayBgColorPicker ? <div style={styles.popover}>
                          <div style={styles.cover} onClick={this.handleBgClose} />
                          <SketchPicker color={this.props.bgColor} onChange={this.handleBgColorChange} />
                        </div> : null}
                      </div>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-qrcode" style={{ backgroundColor: this.props.qrCodeData.bgColor, color: this.props.qrCodeData.fgColor }} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="size" defaultValue={this.props.qrCodeData.size ? this.props.qrCodeData.size : 100} type="number" onChange={this.handleChange} />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-qrcode" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>Render QR as</InputGroupText>
                      </InputGroupAddon>
                      {/* <Input name="size" defaultValue={this.props.qrCodeData.size ? this.props.qrCodeData.size: 100} type="number" onChange={this.handleChange}/> */}
                      <Input type="select" name="renderAs" defaultValue={this.props.qrCodeData.renderAs} onChange={this.handleChange.bind(this)}>
                        <option value="" disabled>Render QR Code as...</option>
                        <option value="svg">SVG</option>
                        <option value="canvas">Canvas</option>
                      </Input>
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="button" onClick={this.download} >
                      <span className="btn-inner--icon">
                        <i className="fas fa-download mr-2" />
                      </span>
                      <span className="btn-inner--text">Download</span>
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
          {/* <div className="modal-footer">
                <Button color="primary" type="button">
                  Save changes
                </Button>
                <Button
                  className="ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("downloadQrModal")}
                >
                  Close
                </Button>
              </div> */}
        </Modal>
      </>
    );
  }
}

DownloadQr.propTypes = {
  qrData: PropTypes.func,
}

export default DownloadQr;