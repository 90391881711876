import React from "react";
// reactstrap components

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  // CardFooter,
  // CardImg,
  // CardImgOverlay,
  // CardTitle,
  // CardText,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ListGroupItem,
  ListGroup,
  Row,
  Col,
  Badge
} from "reactstrap";

import PropTypes from "prop-types";
import TagsInput from "react-tagsinput";
import QRCode from 'qrcode.react';
import { VectorMap } from "react-jvectormap";

//var QRCode = require('..');
class QrCard extends React.Component {
  constructor(props) {
    super(props);
    //this.handleToggleModalChange = this.handleToggleModalChange.bind(this);
  }
  state = {
    value: "http://" + this.props.data.shortDomain + "/" + this.props.data.shortUrl,
    size: 128,
    fgColor: this.props.data.fgColor,
    bgColor: this.props.data.bgColor,
    level: this.props.data.level,
    renderAs: 'canvas',
    includeMargin: true,
    tagsinput: (this.props.data.tags !== null && this.props.data.tags.length > 0) ? this.props.data.tags.split(','): [""],
    isSaving:false 
  };

  notify = (type, title, message) => {
    this.props.notify(type, title, message);
  };

  getContent(event) {
    const url = "http://" + this.props.data.shortDomain + "/" + this.props.data.shortUrl;
    const qrData = {
      value: url,
      size: 128,
      fgColor: this.props.data.fgColor,
      bgColor: this.props.data.bgColor,
      level: this.props.data.level,
      renderAs: 'canvas',
      includeMargin: true,
    };
    this.props.qrData(qrData);
    }

    saveTags = (tagsinput) => {
      this.setState({isSaving: true});
      let formData = new FormData();
      formData.append('qrCodeId', this.props.data.qrCodeId);
      formData.append('tags', tagsinput);
  
      var token = localStorage["token"];
      if(token === undefined || token === '') {
        token = sessionStorage["token"];
      }
      if(token === undefined || token ==='') {
        this.props.history.push('/auth/login');
      }

      var url = process.env.REACT_APP_API_URL;
      fetch(url + '/api/qrcodes/updateqrtags', {
        method: "POST",
        //credentials: 'include',
        body: formData,//JSON.stringify(requestData),
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token,
          //'Content-Type': 'application/json'
        },
      }).then(response => {
        try {
          response.json().then(data =>{
            this.setState({isSaving:false});
            if(data.statusCode === 200 && data.data === true) {
              this.setState({ tagsinput });
            }
            else if(data.statusCode === 404) {
              this.notify("danger", "QR Code not found", "Missing Parameters");
            }
            else if(data.statusCode === 500) {
              this.notify("danger", "Unknown Error", "An unknown error has occurred");
            }
            else {
              this.notify("danger", "Unknown Error", "An unknown error has occurred");
            }
          });
        
        } catch (e) {
          console.log(e);
        }
      }).catch( err => {
        this.setState({isSaving:false});
        this.notify("danger", "Invalid Credentials!", "Authentication failed.");
        console.log(err);
      });
    };

    handleTagsinput = tagsinput => {
      if(tagsinput !== this.state.tagsinput) {
        this.saveTags(tagsinput);
      }
    };

  download = () => { 
    const canvas = document.querySelector('.HpQrcode > canvas');
    var image = canvas.toDataURL("img/png", 1.0).replace("image/png", "image/octet-stream");
    //this.downloadRef.download = 'mypainting.png';
    var link = document.createElement('a');
    link.download = "my-image.png";
    link.href = image;
    link.click();
 }
  render() {
    const url = "http://" + this.props.data.shortDomain + "/" + this.props.data.shortUrl;
    //const codeId = this.props.data.qrCodeId;
    const createdDate = new Date(this.props.data.createdAt);
    const createdDateStr = createdDate.toLocaleDateString(undefined, {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    });
    
    const updatedDate = new Date(this.props.data.updatedAt);
    const updatedDateStr = this.props.data.updatedAt === null ? "____________": updatedDate.toLocaleDateString(undefined, {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    });
    const countriesCount = this.props.stats.statsByCountry === null ? 0 : this.props.stats.statsByCountry.length;
    const countriesString = countriesCount > 1 ? "Countries": "Country";
    const referersCount = this.props.stats.statsByReferer === null ? 0 : this.props.stats.statsByReferer.length;
    const isSaving = this.state.isSaving;
    let mapData = {
      // "AU": 760,
    };
    let markers = [];
    for(var i=0; i<countriesCount; i++) {
      mapData[this.props.stats.statsByCountry[i].label] = this.props.stats.statsByCountry[i].clicks;
    }

    for(i=0; i<countriesCount; i++) {
      if(this.props.stats.statsByCountry[i].country !== null) {
        let marker = {};
        marker.latLng = [this.props.stats.statsByCountry[i].country.latitude, this.props.stats.statsByCountry[i].country.longitude];
        marker.name = this.props.stats.statsByCountry[i].country.geounit + ": " + this.props.stats.statsByCountry[i].clicks;
        markers.push(marker);
      }
    }
    //console.log(JSON.stringify(mapData))

    return (
      <>
        <Card className="card-profile" style={{flex: 1, minWidth: '30%'}}>
          <Col style={{flexBasis:'280px',backgroundColor:'#1a6498'}}>
        <VectorMap
                  containerClassName="vector-map"
                  containerStyle={{
                    width: "100%",
                    height: "250px"
                  }}
                  map={"world_mill"}
                  ref={"map"}
                  zoomOnScroll={false}
                  scaleColors={["#f00", "#0071A4"]}
                  normalizeFunction="polynomial"
                  hoverOpacity={0.7}
                  hoverColor={false}
                  backgroundColor="transparent"
                  markerLabelStyle={{
                    initial: {
                      'font-family': 'Verdana',
                      'font-size': '12',
                      'font-weight': 'bold',
                      cursor: 'default',
                      fill: 'black'
                    },
                    hover: {
                      cursor: 'pointer'
                    }
                  }}
                  regionStyle={{
                    initial: {
                      fill: "#e9ecef",
                      "fill-opacity": 0.8,
                      stroke: "none",
                      "stroke-width": 0,
                      "stroke-opacity": 1
                    },
                    hover: {
                      fill: "#dee2e6",
                      "fill-opacity": 0.8,
                      cursor: "pointer"
                    },
                    selected: {
                      fill: "yellow"
                    },
                    selectedHover: {}
                  }}
                  markerStyle={{
                    initial: {
                      fill: "#fb6340",
                      "stroke-width": 0
                    },
                    hover: {
                      fill: "#11cdef",
                      "stroke-width": 0
                    }
                  }}
                  markers={markers}
                  series={{
                    regions: [
                      {
                        values: mapData,
                        scale: ["#ced4da", "#adb5bd"],
                        normalizeFunction: "polynomial"
                      }
                    ]
                  }}
                /></Col>
          {/* <CardImg alt="..." src={require("assets/img/theme/img-1-1000x600.jpg")} top /> */}
          <Row className="justify-content-center">
            <Col className="order-lg-2" lg="3">
              <div className="card-profile-image">
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  {/* <img alt="..." className="rounded-circle" src={this.props.url} /> */}
                <div className="HpQrcode rounded-circle">
                    
                      <QRCode
                        value={url}
                        size={this.state.size}
                        fgColor={this.props.data.fgColor}
                        bgColor={this.props.data.bgColor}
                        level={this.props.data.level}
                        renderAs={this.state.renderAs}
                        includeMargin={this.state.includeMargin}
                      />
                    
                </div>
                </a>
              </div>
            </Col>
          </Row>
          <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-0" style={{padding:"0.8rem"}}>
            <div className="d-flex justify-content-between">
              <Button className="mr-4" color="info" type="button" onClick={this.props.downloadQrModal} size="sm">
                <span className="btn-inner--icon">
                  <i className="fa fa-pen mr-2" />
                </span>
                <span className="btn-inner--text">Edit</span>
              </Button>
              <Button className="float-right" color="default" type="button" onClick={this.getContent.bind(this) } size="sm" >
                <span className="btn-inner--icon">
                  <i className="fas fa-download mr-2" />
                </span>
                <span className="btn-inner--text">Download</span>
              </Button>
            </div>
          </CardHeader>
          

          <CardBody>
          <div className="text-center">
              <h5 className="h3">
              {this.props.title}
                {/* <span className="font-weight-light">, 27</span> */}
              </h5>
              <div className="h5 font-weight-300">
                <i className="fa fa-chart-line mr-2" />
                Since last month
              </div>
            </div>
          <Row>
              <div className="col mt--4">
                <div className="card-profile-stats d-flex justify-content-center">
                  <div>
                    <span className="heading">{this.props.stats.total_clicks}</span>
                    <span className="description">Click(s)</span>
                  </div>
                  <div>
                    <span className="heading">{countriesCount}</span>
                    <span className="description">{countriesString}</span>
                  </div>
                  <div>
                    <span className="heading">{referersCount}</span>
                    <span className="description">Referer(s)</span>
                  </div>
                </div>
              </div>
            </Row>
            
            {/* <CardTitle className="mb-3" tag="h3">
              {this.props.title}
            </CardTitle> */}
            {isSaving === true && (
            <div className="text-right">
              <Badge color="info">
                Saving...
              </Badge>

            </div>
            )}
            <div className="mb-4">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-tags" />
                    </InputGroupText>
                  </InputGroupAddon>
                  
                  <TagsInput
                    className="bootstrap-tagsinput "
                    onChange={this.handleTagsinput}
                    addOnBlur={this.saveTags}
                    tagProps={{ className: "tag badge mr-1" }}
                    value={this.state.tagsinput}
                    inputProps={{
                        className: "",
                        placeholder: ""
                    }}
                  />
                </InputGroup>
              </FormGroup>
            </div>
            <ListGroup flush>
              
              <ListGroupItem style={{paddingRight:0, paddingLeft:0}}>
                <Button
                  aria-pressed={true} block
                  className="btn-icon btn-3"
                  color="primary"
                  href={this.props.data.longUrl}
                  target="_blank"
                  role="button"
                  size="lg"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-link" />
                  </span>
                  <span className="btn-inner--text">{this.props.data.longUrl}</span>
                  
                </Button>
              
              </ListGroupItem>
              <ListGroupItem style={{paddingRight:0, paddingLeft:0}}>
              <Button
                aria-pressed={true} block
                className="btn-icon btn-3"
                color="info"
                href={url}
                role="button"
                size="lg" target="_blank"
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-link" />
                </span>
                <span className="btn-inner--text">{url}</span>
              </Button>
              
              </ListGroupItem>
              <ListGroupItem style={{paddingRight:0, paddingLeft:0}}>
              <div className="checklist-item checklist-item-success">
              <div className="checklist-info">
                <h5 className="checklist-title mb-0">Created On:</h5>
                <small>{createdDateStr}</small>
              </div>
              <div className="checklist-info">
                <h5 className="checklist-title mb-0">Updated On:</h5>
                <small>{updatedDateStr}</small>
              </div>
              {/* <div>
                <div className="custom-control custom-checkbox custom-checkbox-success">
                  <input
                    className="custom-control-input"
                    defaultChecked
                    id="chk-todo-task-1"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="chk-todo-task-1"
                  />
                </div>
              </div> */}
            </div>

              </ListGroupItem>
            </ListGroup>
            {/* <Button
              color="primary"
              href="#pablo"
              onClick={e => e.preventDefault()}
            >
              Go somewhere
            </Button> */}
            
          </CardBody>
        </Card>
      </>
    );
  }
}

QrCard.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  tags: PropTypes.array,
  description: PropTypes.string,
  qrData: PropTypes.func,
  downloadQrModal: PropTypes.func
}

export default QrCard;