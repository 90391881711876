import { FETCH_ALL_QRCODES, NEW_QRCODE, ERROR_QRCODE, QRCODE_STATS } from "../actions/types";

const initialState = {
    items: [],
    response: {},
    error: {},
    stats: {}
}

export default function(state = initialState, action) {
    switch(action.type) {
        case FETCH_ALL_QRCODES:
            return {
                ...state,
                items: action.payload.data,
                error: {}
            };
        case NEW_QRCODE:
            return {
                ...state,
                items: action.payload.data,
                response: action.payload,
                error: {}
            };
        case QRCODE_STATS:
            return {
                ...state,
                error: {},
                stats: action.payload
            };
        case ERROR_QRCODE:
            return {
                ...state,
                error: action.payload,
                response: action.payload
            }
        
        default:
            return state;
    }
}