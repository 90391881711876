import React from "react";

// reactstrap components
import { Button, Container, Row, Col, Media } from "reactstrap";

class ProfileHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user
    }
  }
  render() {
    return (
      <>
        <div
          className="header pb-6 d-flex align-items-center"
          // style={{
          //   minHeight: "500px",
          //   backgroundImage:
          //     'url("' + require("assets/img/theme/profile-cover.jpg") + '")',
          //   backgroundSize: "cover",
          //   backgroundPosition: "center top"
          // }}
        >
          <span className="mask bg-gradient-info opacity-8" />

          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="12" md="12">
              <Media className="align-items-center pt-4">
                <span className="avatar avatar-xl">
                  <img
                    alt={this.state.user.firstName}
                    src={this.state.user.profile}
                  />
                </span>
                <Media className="ml-2 d-none d-lg-block">
                  <span className="mb-0 text-sm font-weight-bold">
                    <h1 className="display-2 text-white">Hello {this.state.user.firstName}</h1>
                  </span>
                </Media>
              </Media>
                {/* <h1 className="display-2 text-white">Hello {this.state.user.firstName}</h1> */}
                <p className="text-white mt-2 mb-3">
                  Welcome to your profile page. You can always makes changes to your Profile here.
                </p>
                {/* <Button
                  className="btn-neutral"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  Edit profile
                </Button> */}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default ProfileHeader;
