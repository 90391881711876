import React from "react";
import { connect } from 'react-redux';
import { loginUser, forgotPassword } from '../../../actions/userActions';
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components

// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";

import AuthHeader from "components/Headers/AuthHeader.jsx";
import ForgotPassword from "../components/ForgotPassword";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      remember: false,
      forgotPasswordModal: false
    };

    this.toggleForgotPasswordModal = this.toggleForgotPasswordModal.bind(this);
  }

  notify = (type, title, message) => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  toggleForgotPasswordModal = (e, modalState) => {
    e.preventDefault();
    this.setState({
      forgotPasswordModal: !this.state.forgotPasswordModal
    });
    //console.log(this.state.forgotPasswordModal);
  };

  toggleChange = () => {
    this.setState({
      remember: !this.state.remember,
    });
  }

  loginUser = (e) => {
    //e.preventDefault();

    let formData = new FormData();
    formData.append('emailId', this.state.email);
    formData.append('password', this.state.password);

    this.props.loginUser(formData);

  }

  forgotPasswordCall = (email) => {
    //console.log(email);
    let formData = new FormData();
    formData.append('emailId', email);

    this.props.forgotPassword(formData);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.token !== '' && nextProps.user !== undefined && nextProps.user.userId !== undefined) {
      if (this.state.remember === true) {
        localStorage.setItem("rememberMe", this.state.remember);
        localStorage.setItem("token", nextProps.token);
        localStorage.setItem("user", JSON.stringify(nextProps.user));
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("user");
      }
      else {
        sessionStorage.setItem("token", nextProps.token);
        sessionStorage.setItem("user", JSON.stringify(nextProps.user));
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      try {
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', '/auth/login']);
        _hsq.push(["identify", {
          email: nextProps.user.email,
          id: nextProps.user.userId
        }]);
        _hsq.push(['trackPageView']);
      } catch (e) {
        console.log(e);
      }
      this.props.history.push('/admin/dashboard');
    }
    if (nextProps.error.statusCode !== undefined && nextProps.error.statusCode !== 200) {
      if (nextProps.error.statusCode === 400 && nextProps.error.actionCode === 'EMAIL_NOT_VERIFIED') {
        this.notify("danger", "Account Not Verified!", "Please check your email to verifiy your account.");
      }
      else if (nextProps.error.statusCode === 400) {
        this.notify("danger", "Invalid Credentials!", "Email or Password or both are invalid");
      }
      else if (nextProps.error.statusCode === 500) {
        this.notify("danger", "Unknown Error!", "An unknown error has occurred");
      }
      else {
        this.notify("danger", "Unknown Connection Error!", "An unknown error has occurred");
      }
    }
  }

  render() {
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AuthHeader
          title="Welcome to Serve QR!"
          lead="Login to a wonderful realm of Serve QR"
        />
        <Container className="mt--9 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-4">
                    <big>Let's Login</big>
                  </div>
                  <div className="text-center">
                    <i className="fa fa-portrait" style={{ fontSize: 64 }}></i>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-3">
                  <div className="text-center text-muted mb-4">
                    <small>Enter your credentials below</small>
                  </div>
                  <Form role="form">
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: this.state.focusedEmail
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email" onChange={e => this.setState({ email: e.target.value })}
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password" onChange={e => this.setState({ password: e.target.value })}
                          onFocus={() =>
                            this.setState({ focusedPassword: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedPassword: false })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id=" customCheckLogin" checked={this.state.remember}
                        type="checkbox" onChange={this.toggleChange}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor=" customCheckLogin"
                      >
                        <span className="text-muted">Remember me</span>
                      </label>
                    </div>
                    <div className="text-center">
                      <Button className="my-4" color="info" type="button" onClick={e => this.loginUser(e)}>
                        Sign in
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-light"
                    href="#forgotPassword"
                    onClick={e => this.toggleForgotPasswordModal(e, this.state.forgotPasswordModal)}
                  >
                    <small>Forgot password?</small>
                  </a>
                </Col>
                <Col className="text-right" xs="6">
                  <a
                    className="text-light"
                    href="/auth/register"
                  //onClick={e => e.preventDefault()}
                  >
                    <small>Create new account</small>
                  </a>
                </Col>
                <ForgotPassword isOpen={this.state.forgotPasswordModal} toggleModal={this.toggleForgotPasswordModal} resetPassword={this.forgotPasswordCall} />
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userReducer.user,
  token: state.userReducer.token,
  error: state.userReducer.error,
  apiResponse: state.userReducer.apiResponse
});


export default connect(mapStateToProps, { loginUser, forgotPassword })(Login);
