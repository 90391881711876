/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

class Calendar extends React.Component {
  render() {
    let pg = (
    <script type="text/javascript">
      if(window.location.pathname === "/admin/dashboard" || window.location.pathname === "/admin/qrcodes") {
        document.addEventListener('DOMContentLoaded', function () {
          setTimeout(function() {
          particleground(document.getElementById('usercardsheader'), {
            dotColor: '#5cbdaa',
            lineColor: '#5cbdaa',
            density: 6000,
            minSpeedX: 0.5,
            maxSpeedX: 1.5,
            minSpeedY: 0.5,
            maxSpeedY: 1.5,
            particleRadius: 5,
            curvedLines: false,
            multicolor: ['#172b4d','#5e72e4','#11cdef','#2dce89','#f5365c','#fb6340']
          });
          particleground(document.getElementById('sidebar-scrollbar'), {
            dotColor: '#5cbdaa',
            lineColor: '#5cbdaa',
            density: 5000,
            minSpeedX: 0.5,
            maxSpeedX: 1.5,
            minSpeedY: 0.5,
            maxSpeedY: 1.5,
            particleRadius: 5,
            curvedLines: false,
            multicolor: ['#172b4d','#5e72e4','#11cdef','#2dce89','#f5365c','#fb6340']
          });
          particleground(document.getElementById('admin-navbar'), {
            dotColor: '#5cbdaa',
            lineColor: '#5cbdaa',
            density: 6000,
            minSpeedX: 0.5,
            maxSpeedX: 1.5,
            minSpeedY: 0.5,
            maxSpeedY: 1.5,
            particleRadius: 5,
            curvedLines: false,
            multicolor: ['#172b4d','#5e72e4','#11cdef','#2dce89','#f5365c','#fb6340']
          })
        }, 3000)
        })
      }
      </script>
    )
    return (
      <>
        <Container fluid>
          <footer className="footer pt-0">
            <Row className="align-items-center justify-content-lg-between">
              <Col lg="6">
                <div className="copyright text-center text-lg-left text-muted">
                  © {new Date().getFullYear()}{" "}
                  <a
                    className="font-weight-bold ml-1 mr-2"
                    href="https://www.serveqr.com?ref=footer"
                    target="_blank"
                  >
                    Serve QR
                  </a> 
                  Made with <i className="fa fa-heart text-red"></i>
                </div>
              </Col>
              {/* <Col lg="6">
                <Nav className="nav-footer justify-content-center justify-content-lg-end">
                  <NavItem>
                    <NavLink
                      href="https://www.creative-tim.com?ref=adpr-admin-footer"
                      target="_blank"
                    >
                      Creative Tim
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://www.creative-tim.com/presentation?ref=adpr-admin-footer"
                      target="_blank"
                    >
                      About Us
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="http://blog.creative-tim.com?ref=adpr-admin-footer"
                      target="_blank"
                    >
                      Blog
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://www.creative-tim.com/license?ref=adpr-admin-footer"
                      target="_blank"
                    >
                      License
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col> */}
            </Row>
          </footer>
          {pg}
        </Container>
      </>
    );
  }
}

export default Calendar;
