import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal
} from "reactstrap";

import classnames from "classnames";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadQrModal: props.isOpen,
      forgotEmail: ''
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    //this.state.fields = this.props.qrCodeData;
  }


  handleChange = (event) => {
    // console.log(this.props.qrCodeData);
    // console.log(event.target);
    //this.props.qrCodeData.onChange(event.target.value);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'forgotEmail') {
      this.setState({ forgotEmail: value });
    }


    //this.props.qrCodeData = this.props.qrCodeData;
    //this.props.qrCodeData[name] = value;
    //fields[name] = value;
    // this.setState({
    //   fields: fields
    // });
  }

  forgotPassword = (e) => {

    this.props.resetPassword(this.state.forgotEmail);
  }

  toggleModal = state => {
    //console.log("Forgot Password");
    this.setState({
      [state]: !this.state[state]
    });
  };


  render() {
    //console.log(this.props.qrCodeData);
    return (

      <>

        {/* <Button
          color="primary"
          type="button"
          onClick={() => this.toggleModal("downloadQrModal")}
        >
          Edit and Download QR Code
        </Button> */}

        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.isOpen} toggle={this.props.toggleModal}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
              <CardHeader className="bg-transparent pb-3">
                <div className="text-muted text-center mt-2 mb-4">
                  <big>Let's Reset your Password</big>
                </div>
                <div className="text-center">
                  <i className="fa fa-key" style={{ fontSize: 64 }}></i>
                </div>
                <div className="text-center text-muted mt-2">
                  <small>Enter your Email below</small>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-3">
                <div className="text-center text-muted mb-4">
                  <small>You will recieve a Reset Password link if the entered email exists in our system</small>
                </div>
                <Form role="form">
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: this.state.focusedEmail
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email" name="forgotEmail"
                        type="email" onChange={e => this.setState({ forgotEmail: e.target.value })}
                        onFocus={() => this.setState({ focusedEmail: true })}
                        onBlur={() => this.setState({ focusedEmail: false })}
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    <Button className="my-4" color="info" type="button"
                      onClick={e => this.forgotPassword(e)}>
                      Reset Password
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    );
  }
}

export default ForgotPassword;