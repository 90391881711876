import React, { Component } from 'react';
import zxcvbn from 'zxcvbn';

import '../../../assets/css/password-strength-meter.css';

class PasswordStrengthMeter extends Component {
    constructor(props) {
        super(props);
    }

    createPasswordLabel = (result) => {
        switch (result.score) {
          case 0:
            return 'Weak';
          case 1:
            return 'Weak';
          case 2:
            return 'Fair';
          case 3:
            return 'Good';
          case 4:
            return 'Strong';
          default:
            return 'Weak';
        }
      }

      render() {
        const { password } = this.props;
        const testedResult = zxcvbn(password);

        return (
          <div className="password-strength-meter">
            <progress
    className={`password-strength-meter-progress strength-${this.createPasswordLabel(testedResult)}`}
    value={testedResult.score}
    max="4"
  />
            <br />
            <label
              className="password-strength-meter-label text-muted"
            >
              {password && (
                <>
                  <strong>Password strength:</strong><span className={`label-strength-${this.createPasswordLabel(testedResult)} font-weight-700 font-italic`}> {this.createPasswordLabel(testedResult)}</span>
                </>
              )}
            </label>
          </div>
        );
      }
}

export default PasswordStrengthMeter;