import { LOGIN_USER, ERROR_LOGIN_USER, FORGOT_PASSWORD, ERROR_FORGOT_PASSWORD } from "../actions/types";

const initialState = {
    user: {},
    token: '',
    error: {},
    apiResponse: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_USER:
            //console.log(action.payload)
            return {
                ...state,
                user: action.payload.data.user,
                token: action.payload.data.token,
                error: {}
            };
        case ERROR_LOGIN_USER:
            return {
                ...state,
                error: action.payload,
                user: {},
                token: ''
            };

        case FORGOT_PASSWORD:
            //console.log(action.payload)
            return {
                ...state,
                user: {},
                apiResponse: action.payload,
                error: {},
                token: ''
            };
        case ERROR_FORGOT_PASSWORD:
            return {
                ...state,
                error: action.payload,
                user: {},
                token: '',
                apiResponse: {}
            }

        default:
            return state;
    }
}