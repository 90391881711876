export function formatDate(dt) {
    var date = new Date(dt);
    var strDate = "";
    strDate = strDate +  date.getFullYear() + "-";
    if((date.getMonth() + 1) < 10)
        strDate = strDate + "0" + (date.getMonth() + 1) + "-";
    else
        strDate = strDate + (date.getMonth() + 1) + "-";
    strDate = strDate + date.getDate();
    return strDate;
}

export function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function addMonth(date, month) {
    var result = new Date(date);
    result.setMonth(result.getMonth() + month);
    return result;
}

export function validateEmail(email) 
{
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
  {
    return (true)
  }
    return (false)
}