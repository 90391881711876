import React from "react";
import { connect } from 'react-redux';
import { getQrCodeStats } from '../../../actions/qrcodeActions';
import {
    Badge,
    Media,
    UncontrolledTooltip
  } from "reactstrap";
  import nanoid from 'nanoid';
class DashboardDetailTable extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let tbody = [];
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let monthsNum = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        let avatarColors = ["bg-primary", "bg-default", "bg-danger", "bg-success", "bg-warning", "bg-info"];
        if(this.props.qrData !== undefined) {
            let qrData = this.props.qrData;
            
            for (let i = 0; i < qrData.length; i++) {
                let qr = qrData[i];
                let qrDetails = qr.item1;
                let qrStats = qr.item2;
                let statsByDay = qr.item2.statsByDay;
                let monthStats = [];
                let dt = new Date();
                for(let k=0;k<6;k++) {
                    let key = dt.getFullYear() + "-" + monthsNum[dt.getMonth()];
                    monthStats[key] = {x: months[dt.getMonth()] + " - " + dt.getFullYear(), y: 0};
                    dt.setMonth(dt.getMonth() - 1);
                }
                if(statsByDay !== null && statsByDay !== undefined) {
                    for(let j=0;j<statsByDay.length; j++) {
                        let stat = statsByDay[j];
                        let statArr = stat.x.split('-');
                        let statMonth = parseInt(statArr[1]);
                        //let statYear = parseInt(statArr[0]);
                        
                        if(monthStats[stat.x.slice(0,7)] === undefined) {
                            monthStats[stat.x.slice(0,7)] = {x: months[statMonth - 1] + " - " + stat.x.slice(0,4), y: stat.y};
                        }
                        else {
                            monthStats[stat.x.slice(0,7)].y += stat.y;
                        }
                    }
                }
                // let labels = [];
                let keys = Object.keys(monthStats)
                // let data = [];
                let stats = [];
                for (let i=0;i<keys.length;i++) {
                    let sdata = monthStats[keys[i]];
                    let toolTip = "tooltip" + nanoid(9);
                    let tstat = (
                        <>
                            <a className={avatarColors[i] + " avatar avatar-sm rounded-circle"} href="#" id={toolTip} onClick={e => e.preventDefault()} color="primary">
                                {sdata.x.slice(0,4)}
                            </a>
                            <UncontrolledTooltip delay={0} target={toolTip}>
                                {sdata.x + ": " + sdata.y+ " Click(s)"}
                            </UncontrolledTooltip>
                        </>
                    )
                    stats.push(tstat);
                    //labels.push(sdata.x);
                    //data.push(sdata.y);
                }
                stats.reverse();

                let tags = qrDetails.tags.split(',');
                let ttags = [];
                for (let ti = 0; ti < tags.length; ti++) {
                    let ctag = (
                    <Badge  color="info" pill style={{background:'#172b4d'}}>{tags[ti]}</Badge>
                    );
                    ttags.push(ctag);
                }

                let bd = (
                    <tr 
                    // style={{width: '100%', display: 'inline-table', tableLayout: 'fixed'}}
                    >
                        <th scope="row">
                            <Media className="align-items-center">
                                {/* <a className="avatar rounded-circle mr-3" href="#" onClick={e => e.preventDefault()} >
                                    <img alt="..." src={require("assets/img/theme/bootstrap.jpg")} />
                                </a> */}
                                <Media>
                                    <span className="name mb-0 text-sm">{qrDetails.title}</span>
                                </Media>
                            </Media>
                        </th>
                        <td>
                            <Badge className="badge-lg" color="primary" href={qrDetails.longUrl} onClick={e => e.preventDefault()} pill style={{textTransform: 'initial', fontSize:'85%'}}>
                                {qrDetails.longUrl}
                            </Badge>
                        </td>
                        <td>
                            <Badge className="badge-lg" color="success" href={'https://' + qrDetails.shortDomain + '/' + qrDetails.shortUrl} onClick={e => e.preventDefault()} pill style={{textTransform: 'initial', fontSize:'85%'}}>
                                https://{qrDetails.shortDomain}/{qrDetails.shortUrl}
                            </Badge></td>
                        <td className="budget">{qrStats.total_clicks}</td>
                        <td>
                            <Badge className="badge-dot mr-4" color="">
                                <i className={qrDetails.status === "active"?"bg-success":qrDetails.status === "inactive"?"bg-danger":"bg-warning"} />
                                <span className="status">{qrDetails.status}</span>
                            </Badge>
                        </td>
                        <td>
                        <div className="avatar-group">
                            {stats}
                        </div>
                        </td>
                        <td>
                            {ttags}
                        </td>
                        <td className="text-right">
                        {/* <UncontrolledDropdown>
                            <DropdownToggle
                            color=""
                            size="sm"
                            className="btn-icon-only text-light"
                            >
                            <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu
                            className="dropdown-menu-arrow"
                            right
                            >
                            <DropdownItem
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                            >
                                Action
                            </DropdownItem>
                            <DropdownItem
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                            >
                                Another action
                            </DropdownItem>
                            <DropdownItem
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                            >
                                Something else here
                            </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown> */}
                        </td>
                    </tr>
                )
                tbody.push(bd);
            }
        }

        return (
            <>
                {tbody}
            </>
        );
    }
}

export default DashboardDetailTable;