
import React from "react";
import PropTypes from "prop-types";
import md5 from "md5";
import { connect } from 'react-redux';
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import ProfileHeader from "components/Headers/User/ProfileHeader.jsx";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    let user = {};
    if(this.props.userData !== undefined) {
      user = this.props.userData;
      //this.setState({userData: user});
      if(user !== undefined && user.email !== undefined && user.email !== '') {
        let emailHash = user.email;
        emailHash = emailHash.trim();
        emailHash = emailHash.toLowerCase();
        emailHash = md5(emailHash);
        user['emailHash'] = emailHash;
        let profile = 'https://www.gravatar.com/avatar/' + emailHash;
        user['profile'] = profile;
        //user.isEmailVerified = false;
      }
      this.state = {
        userData: user
      }
    }
    else {
      this.state = {
        userData: {}
      }
    }
  }
  render() {
    return (
      <>
        <ProfileHeader user={this.state.userData} />
        <Container className="mt--6" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card>
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Edit profile</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        Save Profile
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              First name
                            </label>
                            <Input
                              defaultValue="Lucky"
                              id="input-first-name"
                              placeholder="First name"
                              type="text"
                              value={this.state.userData.firstName}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Last name
                            </label>
                            <Input
                              defaultValue="Jesse"
                              id="input-last-name"
                              placeholder="Last name"
                              type="text"
                              value={this.state.userData.lastName}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="dob-date-input"
                            >
                              Date of Birth
                            </label>
                            <Input
                              id="dob-date-input" 
                              type="date" value={this.state.userData.birthDate}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Email address
                              </label>
                              {this.state.userData.isEmailVerified === true && (
                              <Badge color="success" pill className="float-right">
                                Verified
                              </Badge>
                              )}
                              {this.state.userData.isEmailVerified === false && (
                              <div className="float-right">
                                <Badge color="danger" pill>
                                  Not Verified
                                </Badge>
                                <Button className="px-0 ml-1" size="sm" color="link" href="#" onClick={e => e.preventDefault()}>
                                  Verify
                                </Button>
                              </div>
                              )}
                            <Input
                              id="input-email"
                              placeholder="jesse@serveqr.com"
                              type="email" disabled
                              value={this.state.userData.email}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="user-tel-input">
                              Mobile Phone
                            </label>
                            {this.state.userData.isMobileVerified === true && (
                              <Badge color="success" pill className="float-right">
                                Verified
                              </Badge>
                              )}
                              {this.state.userData.isMobileVerified === false && (
                              <div className="float-right">
                                <Badge color="danger" pill>
                                  Not Verified
                                </Badge>
                                <Button className="px-0 ml-1" size="sm" color="link" href="#" onClick={e => e.preventDefault()}>
                                  Verify
                                </Button>
                              </div>
                              )}
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fa fa-mobile"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                value={this.state.userData.mobile}
                                id="user-tel-input"
                                type="tel" placeholder="+12345678910"
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      
                    </div>
                    <hr className="my-4" />

                    <h6 className="heading-small text-muted mb-4">
                      Contact information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Address
                            </label>
                            <Input
                              defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                              id="input-address"
                              placeholder="Home Address"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              City
                            </label>
                            <Input
                              defaultValue="New York"
                              id="input-city"
                              placeholder="City"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Country
                            </label>
                            <Input
                              defaultValue="United States"
                              id="input-country"
                              placeholder="Country"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Postal code
                            </label>
                            <Input
                              id="input-postal-code"
                              placeholder="Postal code"
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    {/* <hr className="my-4" />

                    <h6 className="heading-small text-muted mb-4">About me</h6>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label className="form-control-label">About Me</label>
                        <Input
                          placeholder="A few words about you ..."
                          rows="4"
                          type="textarea"
                          defaultValue="A beautiful premium dashboard for Bootstrap 4."
                        />
                      </FormGroup>
                    </div> */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.userReducer.user
});

export default connect(mapStateToProps)(Profile);
