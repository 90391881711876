import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from './reducers';
import {loadState, saveState} from './localStorage';
import throttle from "lodash";
const initialState = {};

const middleware = [thunk];

const persistedState = loadState();

const store = createStore(
    rootReducer, 
    persistedState, 
    applyMiddleware(...middleware)
);

store.subscribe(()=> {
    saveState(store.getState());
});

export default store;