/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";

class Pricing extends React.Component {
  render() {
    return (
      <>
        <AuthHeader title="Choose the best plan for you" lead="
                <strong>Serve QR</strong> is in Beta and hence is free for time being.
                We will introduce similar pricing in the future when everything is perfectly stable.
                Till then Enjoy!" />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="10">
              <div className="pricing card-group flex-column flex-md-row mb-3">
                <Card className="card-pricing border-0 text-center mb-4">
                  <CardHeader className="bg-transparent">
                    <h4 className="text-uppercase ls-1 text-info py-3 mb-0">
                      Delta pack
                    </h4>
                  </CardHeader>
                  <CardBody className="px-lg-7">
                    <div className="display-2"><del>$9</del></div>
                    <span className="text-muted">per month</span>
                    <ul className="list-unstyled my-4">
                      <li>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                              <i className="fas fa-qrcode" />
                            </div>
                          </div>
                          <div>
                            <span className="pl-2">5 Dynamic QR Codes</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                              <i className="ni ni-chart-bar-32" />
                            </div>
                          </div>
                          <div>
                            <span className="pl-2">
                              1 Month Click Data 
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                              <i className="fas fa-mobile" />
                            </div>
                          </div>
                          <div>
                            <span className="pl-2">500 Scans</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <Button
                        className="my-2"
                        color="default"
                        to="/auth/register"
                        tag={Link}
                      >
                        Start for Free
                    </Button>
                  </CardBody>
                  {/* <CardFooter>
                    <a
                      className="text-light"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      Request a demo
                    </a>
                  </CardFooter> */}
                </Card>
                
                <Card className="card-pricing bg-gradient-success zoom-in shadow-lg rounded border-0 text-center mb-4">
                  <CardHeader className="bg-transparent">
                    <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                      Alpha pack
                    </h4>
                  </CardHeader>
                  <CardBody className="px-lg-7">
                    <div className="display-1 text-white"><del> $49 </del></div>
                    <span className="text-white">per month</span>
                    <ul className="list-unstyled my-4">
                      <li>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                              <i className="fas fa-qrcode" />
                            </div>
                          </div>
                          <div>
                            <span className="pl-2 text-white">
                              30 Dynamic QR Codes
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                              <i className="ni ni-chart-bar-32" />
                            </div>
                          </div>
                          <div>
                            <span className="pl-2 text-white">
                              6 Months Click Data 
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                              <i className="fas fa-mobile" />
                            </div>
                          </div>
                          <div>
                            <span className="pl-2 text-white">
                              5000 Scans
                            </span>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <Button
                        className="btn-neutral my-2"
                        color="default"
                        to="/auth/register"
                        tag={Link}
                      >
                        Start for Free
                    </Button>
                  </CardBody>
                  {/* <CardFooter className="bg-transparent">
                    <a
                      className="text-white"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      Contact sales
                    </a>
                  </CardFooter> */}
                </Card>
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-content-lg-center px-3 mt-5">
            <div>
              <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
                <i className="fa fa-qrcode text-primary" />
              </div>
            </div>
            <Col lg="6">
              <p className="text-white">
                <strong>Serve QR</strong> is in Beta and hence is free for time being.
                We will introduce similar pricing in the future when everything is perfectly stable.
                Till then Enjoy!
              </p>
            </Col>
          </div>
          <Row className="row-grid justify-content-center">
            <Col lg="10">
              <Table className="table-dark mt-5" responsive>
                <thead>
                  <tr>
                    <th className="px-0 bg-transparent" scope="col">
                      <span className="text-light font-weight-700">
                        Features
                      </span>
                    </th>
                    <th className="text-center bg-transparent" scope="col">
                      Delta Pack
                    </th>
                    <th className="text-center bg-transparent" scope="col">
                      Alpha Pack
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-0">Dynamic QR Codes</td>
                    <td className="text-center">
                      <span className="text-sm text-light">
                        Limited to 5 QR Codes
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="text-sm text-light">
                        Limited to 30 QR Codes
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">QR Code Scans</td>
                    <td className="text-center">
                      <span className="text-sm text-light">
                        Limited to 500 Scans
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="text-sm text-light">
                        Limited to 5000 Scans
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">Active Dashboard</td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">Multiple Short Domains</td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">Graph Data</td>
                    <td className="text-center">
                      <span className="text-sm text-light">
                        Limited to 1 Month
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="text-sm text-light">
                        Limited to 6 Months
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">Scheduled links</td>
                    <td className="text-center">
                      <i className="fas fa-times text-danger" />
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">Many more features</td>
                    <td className="text-center">
                      <span className="text-sm text-light">
                        Coming soon*
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="text-sm text-light">
                        Coming soon*
                      </span></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Pricing;
