import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";
import NotificationAlert from "react-notification-alert";
import PasswordStrengthMeter from "../components/PasswordStrengthMeter";
import { validateEmail } from "../../../utils/helper.js";


class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      privacy: '',
      firstNameValidationClass: '',
      lastNameValidationClass: '',
      emailValidationClass: '',
      passwordValidationClass: '',
      privacyValidationClass: ''
    };
  }

  notify = (type, title, message) => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'privacy')
      this.setState({ privacy: value });
  }

  validateRegister() {
    if (this.state.firstName === '')
      this.setState({ firstNameValidationClass: 'is-invalid' });
    else
      this.setState({ firstNameValidationClass: 'is-valid' });

    if (this.state.lastName === '')
      this.setState({ lastNameValidationClass: 'is-invalid' });
    else
      this.setState({ lastNameValidationClass: 'is-valid' });

    if (validateEmail(this.state.email) === false)
      this.setState({ emailValidationClass: 'is-invalid' });
    else
      this.setState({ emailValidationClass: 'is-valid' });

    if (this.state.password === '')
      this.setState({ passwordValidationClass: 'is-invalid' });
    else
      this.setState({ passwordValidationClass: 'is-valid' });

    if (this.state.privacy === true)
      this.setState({ privacyValidationClass: 'is-valid' });
    else
      this.setState({ privacyValidationClass: 'is-invalid' });
  };


  handleRegister(e) {
    e.preventDefault();
    this.validateRegister();
    if ((this.state.firstNameValidationClass && this.state.lastNameValidationClass && this.state.emailValidationClass && this.state.passwordValidationClass && this.state.privacyValidationClass) !== 'is-valid') {
      this.notify('danger', 'Error in Registering', 'Please fill in the required details!');
      return false;
    }
    else {
      //console.log("Registering");
      let requestData = {};
      requestData.email = this.state.email;
      requestData.firstName = this.state.firstName;
      requestData.lastName = this.state.lastName;
      requestData.password = this.state.password;
      var url = process.env.REACT_APP_API_URL;
      //const qr = getState().qrcodes.qr;
      //console.log(qr);

      fetch(url + '/api/user/register', {
        method: "POST",
        body: JSON.stringify(requestData),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }).then(response => {
        response.json().then(data => {
          if (data.statusCode === 200 && data.actionCode === 'USER_REGISTERED_EMAIL_NOT_VERIFIED') {
            try {
              var _hsq = window._hsq = window._hsq || [];
              _hsq.push(['setPath', '/auth/register']);
              _hsq.push(["identify", {
                email: data.data.user.email,
                id: data.data.user.userId,
                firstName: data.data.user.firstName,
                lastName: data.data.user.lastName
              }]);
              _hsq.push(['trackPageView']);
            } catch (e) {
              console.log(e);
            }
            this.notify('success', 'User Registered Successfully!', 'User is successfully registered. Please check your email for the Verification Email.');
            return true;
          }
          else if (data.statusCode === 400 && data.actionCode === 'MISSING_USER_DATA') {
            this.notify('danger', 'Missing Information!', 'Uh Oh! You missed providing the required informations.');
            return false;
          }
          else if (data.statusCode === 400 && data.actionCode === 'USER_EXISTS') {
            this.notify('warning', 'User Exists!', 'User with this Email already Exists.');
            return false;
          }
          else if (data.statusCode === 500) {
            this.notify('danger', 'An Unknown Error Occurred!', 'Please try after sometime. Else contact us at support@serveqr.com');
            return false;
          }
          else {
            this.notify('danger', 'An Unknown Error Occurred!', 'Please contact us at support@serveqr.com');
            return false;
          }
        })
      });

    }
  }


  handleResponse(clientResponse) {
    console.info(JSON.stringify(clientResponse.successResponse.user, null, 2));
  }

  render() {
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AuthHeader
          title="Create an account"
          lead="Let's get you an account with us for our services."
        />
        <Container className="mt--9 pb-5">
          <Row className="justify-content-center">
            <Col lg="6" md="8">
              <Card className="bg-secondary border-0">
                <CardHeader className="bg-transparent pb-4">
                  <div className="text-muted text-center mt-2 mb-4">
                    <big>Register</big>
                  </div>
                  <div className="text-center">
                    <i className="fa fa-portrait" style={{ fontSize: 64 }}></i>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-3">
                  <div className="text-center text-muted mb-4">
                    <small>Enter all the details below</small>
                  </div>
                  <Form role="form">
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedName
                      })}
                    >
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-hat-3" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="First Name" className={this.state.firstNameValidationClass}
                          type="text" onChange={e => this.setState({ firstName: e.target.value })}
                          onFocus={() => this.setState({ focusedName: true })}
                          onBlur={() => this.setState({ focusedName: false })}
                        />
                        {/* <InputGroupAddon addonType="append"> */}
                        <Input
                          placeholder="Last Name" className={this.state.lastNameValidationClass}
                          type="text" onChange={e => this.setState({ lastName: e.target.value })}
                          onFocus={() => this.setState({ focusedName: true })}
                          onBlur={() => this.setState({ focusedName: false })}
                        />
                        {/* </InputGroupAddon> */}

                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedEmail
                      })}
                    >
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email" className={this.state.emailValidationClass}
                          type="email" onChange={e => this.setState({ email: e.target.value })}
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword
                      })}
                    >
                      <InputGroup className="">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input className={this.state.passwordValidationClass}
                          placeholder="Password" type="password" onChange={e => this.setState({ password: e.target.value })}
                          onFocus={() =>
                            this.setState({ focusedPassword: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedPassword: false })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <PasswordStrengthMeter password={this.state.password} />
                    {/* <div className="text-muted font-italic">
                      <small>
                        password strength:{" "}
                        <span className="text-success font-weight-700">
                          strong
                        </span>
                      </small>
                    </div> */}
                    <Row className="my-4">
                      <Col xs="12">
                        <div className="custom-control custom-checkbox">
                          <input
                            className={classnames(this.state.privacyValidationClass, 'custom-control-input')}
                            id="customCheckRegister" value="accept" name="privacy"
                            type="checkbox" onChange={e => this.handleChange(e)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheckRegister"
                          >
                            <span className="text-muted">
                              I agree with the{" "}
                              <a
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                Privacy Policy
                              </a>
                            </span>
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <div className="text-center">
                      <Button className="mt-4 mb-4" color="info" type="button" onClick={e => this.handleRegister(e)}>
                        Create account
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Register;
