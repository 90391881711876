import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import { connect } from 'react-redux';
import { newQrCode } from '../../../actions/qrcodeActions';

import classnames from "classnames";
import TagsInput from "react-tagsinput";
import PropTypes from "prop-types";
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color';
import nanoid from 'nanoid';

import ReactBSAlert from "react-bootstrap-sweetalert";
class AddQrCodeModal extends React.Component {
  constructor(props) {
    super(props);
    //shortid.characters('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    this.state = {
      fields: {
        title: '',
        url: '',
        shortDomain: '4wd.to',
        qrCodeCorrect: 'l',
        shortCode: props.shortCode
      },
      tagsinput: ["my-qr"],
      titleValidationClass: '',
      urlValidationClass: '',
      shortCodeValidationClass: '',
      addQrFormModal: props.isOpen,
      displayFgColorPicker: false,
      displayBgColorPicker: false,
      bgColor: '#ffffff',
      fgColor: '#000000',
      color: {
        r: '241',
        g: '112',
        b: '19',
        a: '1',
      },
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  successAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      )
    });
  };

  warningAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Oops"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      )
    });
  };


  handleFgClick = () => {
    this.setState({ displayFgColorPicker: !this.state.displayFgColorPicker })
  };
  handleBgClick = () => {
    this.setState({ displayBgColorPicker: !this.state.displayBgColorPicker })
  };

  handleFgClose = () => {
    this.setState({ displayFgColorPicker: false })
  };
  handleBgClose = () => {
    this.setState({ displayBgColorPicker: false })
  };

  handleBgColorChange = (color) => {
    //this.props.qrCodeData.bgColor = color.hex;
    this.setState({ bgColor: color.hex })
  };
  handleFgColorChange = (color) => {
    //this.props.qrCodeData.fgColor = color.hex;
    this.setState({ fgColor: color.hex })
  };


  toggleModal = state => {

    this.setState({ shortCode: nanoid(5) })
    //console.log(this.state.shortCode);
    this.setState({
      [state]: !this.state[state]
    });

  };

  handleChange(event) {
    const target = event.target;
    let fields = this.state.fields;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    fields[name] = value;
    this.setState({
      fields
    });
  }

  validate() {
    if (this.state.fields.title === '')
      this.setState({ titleValidationClass: 'is-invalid' });
    else
      this.setState({ titleValidationClass: 'is-valid' });

  }

  notify = (type, title, message) => {
    //this.props.notify(type, title, message);
    let options = {
      place: "tl",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  validateShortCode = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append('urlEnding', this.state.fields.shortCode);
    formData.append('domain', this.state.fields.shortDomain);

    var url = process.env.REACT_APP_API_URL;
    //console.log(url);
    fetch(url + '/api/qrcodes/checkshorturlexist', {
      method: "POST",
      //credentials: 'include',
      body: formData,//JSON.stringify(requestData),
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.state.token
        //'Content-Type': 'application/json'
      },
    }).then(response => {
      response.json().then(data => {
        if (data.statusCode === 200 && data.data === false) {
          this.setState({ shortCodeValidationClass: 'is-valid' });
          this.successAlert("Short Code is Valid!");
        }
        else if (data.statusCode === 200 && data.data === true) {
          this.setState({ shortCodeValidationClass: 'is-invalid' });
          this.warningAlert("Short Code is already in use. Please try another...");
        }
        else if (data.statusCode === 400) {
          this.warningAlert(data.statusMessage);
        }
        else if (data.statusCode === 500) {
          this.warningAlert("An unknown error has occurred");
        }
        else {
          this.warningAlert("An unknown error has occurred");
        }
      })
    }).catch(err => {
      this.warningAlert("An unknown error has occurred");
    });
  }

  generateShortCode = (e) => {
    e.preventDefault();
    let fields = this.state.fields;
    fields.shortCode = nanoid(5);
    this.setState({ fields: fields });
  }

  handleTagsinput = tagsinput => {
    this.setState({ tagsinput });
  };

  addQrCode = (e) => {
    e.preventDefault();

    const requestBody = {
      LongUrl: this.state.fields.url,
      ShortUrl: this.state.fields.shortCode,
      ShortDomain: this.state.fields.shortDomain,
      Tags: this.state.tagsinput.toString(),
      FgColor: this.state.fgColor,
      BgColor: this.state.bgColor,
      QrLevel: this.state.fields.qrCodeCorrect,
      Title: this.state.fields.title
    };


    this.props.newQrCode(requestBody);
    // var url = process.env.REACT_APP_API_URL;

    // fetch(url + '/api/qrcodes/addqrcode', {
    //   method: "POST",
    //   body: JSON.stringify(requestBody),
    //   headers: {
    //     'Accept': 'application/json',
    //     'Authorization': 'Bearer ' + this.state.token,
    //     'Content-Type': 'application/json'
    //   },
    // }).then(response => {
    //   response.json().then(data =>{
    //     console.log(data);
    //     if(data.statusCode === 200 && data.data === false) {
    //       this.setState({shortCodeValidationClass: 'is-valid'});
    //       this.successAlert("Short Code is Valid!");
    //     }
    //     else if(data.statusCode === 200 && data.data === true) {
    //       this.setState({shortCodeValidationClass: 'is-invalid'});
    //       this.warningAlert("Short Code is already in use. Please try another...");
    //     }
    //     else if(data.statusCode === 400) {
    //       this.warningAlert(data.statusMessage);
    //     }
    //     else if(data.statusCode === 500) {
    //       this.warningAlert("An unknown error has occurred");
    //     }
    //     else {
    //       this.warningAlert("An unknown error has occurred");
    //     }
    //   })
    // }).catch( err => {
    //     this.warningAlert("An unknown error has occurred");
    // })
  }

  componentWillReceiveProps(nextProps) {
    let fields = this.state.fields;
    fields = {
      title: '',
      url: '',
      shortDomain: '4wd.to',
      qrCodeCorrect: 'l',
      shortCode: this.props.shortCode
    }
    //fields.shortCode = this.props.shortCode;
    this.setState({ fields: fields });
    this.setState({ token: sessionStorage["token"] });
    //console.log(this.state.fields.shortCode);
    if (this.props.isOpen) {
      if (nextProps.response !== undefined && nextProps.response.data !== undefined && nextProps.response.data.length > 0 && nextProps.error.statusCode === undefined) {
        this.successAlert("QR Code Generated Successfully!");
      }
      else if (nextProps.error.statusCode !== undefined) {
        this.warningAlert(nextProps.error.statusMessage);
      }
    }

  }

  render() {

    const styles = reactCSS({
      'default': {
        fgColor: {
          width: '50px',
          height: '20px',
          borderRadius: '2px',
          background: this.state.fgColor //`rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
        },
        bgColor: {
          width: '50px',
          height: '20px',
          borderRadius: '2px',
          background: this.state.bgColor //`rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    //const isDataAvailable = (this.props.qrCodeData != null && this.props.qrCodeData.value != null)
    const tooltipId = 'tooltip' + nanoid();

    return (
      <>
        {this.state.alert}
        <Row>
          <Col md="6">
            <Modal
              className="modal-dialog-centered"
              size="md"
              isOpen={this.props.isOpen} toggle={this.props.toggleModal}
            //toggle={() => this.toggleModal("addQrFormModal")}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-transparent pb-4">
                    <div className="text-muted text-center mt-2 mb-3">
                      <label>Let's generate your Dynamic QR Code</label>
                    </div>
                    <div className="btn-wrapper text-center">
                      <div className="center header">
                        <i className="fa fa-qrcode" style={{ fontSize: 48 }}></i>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-4 py-lg-2">
                    <div className="text-center text-muted mb-3">
                      <small>Please enter the required details</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <InputGroup className={classnames({ focused: this.state.default && this.state.default.basicURL })}>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-pen" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Title" required name="title" type="text" value={this.state.fields.title || ''} onChange={this.handleChange} />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <InputGroup className={classnames({ focused: this.state.default && this.state.default.basicURL })}>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-link" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="URL" name="url" required type="url" value={this.state.fields.url || ''} onChange={this.handleChange} />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <InputGroup className={classnames({ focused: this.state.default && this.state.default.basicURL && this.state.sizing && this.state.sizing.default })}>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-globe-americas" />
                            </InputGroupText>
                            <InputGroupText>
                              https://
                            </InputGroupText>
                          </InputGroupAddon>
                          {/* <Input className="col-md-2" value="https://" disabled /> */}
                          <Input className="col-md-3" type="select" value={this.state.fields.shortDomain} onChange={this.handleChange} name="shortDomain">
                            <option value="" disabled>Shorten URL using...</option>
                            <option value="4wd.to">4wd.to</option>
                          </Input>
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              /
                            </InputGroupText>
                          </InputGroupAddon>
                          {/* <Input className="col-md-1" value="/" disabled /> */}
                          <Input placeholder="Short Code" name="shortCode" required type="text" minLength="3" className={this.state.shortCodeValidationClass} value={this.state.fields.shortCode || ''} onChange={this.handleChange} />
                          <Button color="primary" id={tooltipId} type="button" size="sm" className="form-control col-md-1" onClick={e => this.generateShortCode(e)}>
                            <span className="btn-inner--icon">
                              <i className="fas fa-sync" />
                            </span>
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            placement="left"
                            target={tooltipId}
                          >
                            Click to Generate random short code
                          </UncontrolledTooltip>
                        </InputGroup>
                        <h6 className="float-right">
                          <a href="#" onClick={e => this.validateShortCode(e)}>Check Availability</a>
                        </h6>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <InputGroup className={classnames({ focused: this.state.default && this.state.default.basicURL })}>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-qrcode" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input type="select" name="qrCodeCorrect" value={this.state.fields.qrCodeCorrect} onChange={this.handleChange}>
                            <option value="" disabled>QR Code Error Correction...</option>
                            <option value="l">L (Low)</option>
                            <option value="m">M (Medium)</option>
                            <option value="q">Q (Quartile)</option>
                            <option value="h">H (High)</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-qrcode" style={{ backgroundColor: this.state.bgColor, color: this.state.fgColor }} />
                            </InputGroupText>
                          </InputGroupAddon>
                          <label className="form-control">Foreground</label>
                          <div className="form-control">
                            <div style={styles.swatch} onClick={this.handleFgClick}>
                              <div style={styles.fgColor} />
                            </div>
                            {this.state.displayFgColorPicker ? <div style={styles.popover}>
                              <div style={styles.cover} onClick={this.handleFgClose} />
                              <SketchPicker color={this.state.fgColor} onChange={this.handleFgColorChange} />
                            </div> : null}
                          </div>
                          <label className="form-control">Background</label>
                          <div className="form-control">
                            <div style={styles.swatch} onClick={this.handleBgClick}>
                              <div style={styles.bgColor} />
                            </div>
                            {this.state.displayBgColorPicker ? <div style={styles.popover}>
                              <div style={styles.cover} onClick={this.handleBgClose} />
                              <SketchPicker color={this.state.bgColor} onChange={this.handleBgColorChange} />
                            </div> : null}
                          </div>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-tags" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <TagsInput
                            className="bootstrap-tagsinput form-control"
                            onChange={this.handleTagsinput}
                            tagProps={{ className: "tag badge mr-1" }}
                            value={this.state.tagsinput}
                            inputProps={{
                              className: "",
                              placeholder: ""
                            }}
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="submit"
                          onClick={this.addQrCode}
                        >
                          Generate
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>
          </Col>
        </Row>
      </>
    );
  }
}

AddQrCodeModal.propTypes = {
  newQrCode: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  items: state.qrCodeReducer.items,
  error: state.qrCodeReducer.error,
  response: state.qrCodeReducer.response
});

export default connect(mapStateToProps, { newQrCode })(AddQrCodeModal);