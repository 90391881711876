import { FETCH_ALL_QRCODES, NEW_QRCODE, ERROR_QRCODE, QRCODE_STATS } from "./types";

export function newQrCode(requestBody) {
    return function (dispatch, getState) {
        var url = process.env.REACT_APP_API_URL;
        var rememberMe = localStorage["rememberMe"];
        var token = getState().userReducer.token;
        if (token === undefined && rememberMe === true) {
            token = localStorage["token"];
            if (token === undefined || token === '') {
                console.log("redirectingaction");
                this.props.history.push('/auth/login');
            }
        }
        else if (token === undefined) {
            token = sessionStorage["token"];
            if (token === undefined || token === '') {
                console.log("redirectingaction");
                this.props.history.push('/auth/login');
            }
        }
        fetch(url + '/api/qrcodes/addqrcode', {
            method: "POST",
            body: JSON.stringify(requestBody),
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
        }).then(response => {
            response.json().then(data => {
                //console.log(data);
                if (data.statusCode === 200 && data.actionCode === "QRCODE_ADD_SUCCESSFUL") {
                    dispatch({
                        type: NEW_QRCODE,
                        payload: data
                    });
                    //this.setState({shortCodeValidationClass: 'is-valid'});
                    //this.successAlert("Short Code is Valid!");
                }
                else {
                    dispatch({
                        type: ERROR_QRCODE,
                        payload: data
                    });
                    //this.setState({shortCodeValidationClass: 'is-invalid'});
                    //this.warningAlert("Short Code is already in use. Please try another...");
                }
            })
        }).catch(err => {
            dispatch({
                type: ERROR_QRCODE,
            })
            //this.warningAlert("An unknown error has occurred");
        });
    }
}

export function fetchAllQrCodes() {
    return function (dispatch, getState) {


        var rememberMe = localStorage["rememberMe"];
        var token = getState().userReducer.token;
        var user = getState().userReducer.user;
        if (token === '' && rememberMe === true) {
            token = localStorage["token"];
            user = localStorage["user"];
            if (token === undefined || token === '') {
                console.log("redirectingaction");
                this.props.history.push('/auth/login');
            }
        }
        else if (token === undefined || token === '') {
            token = sessionStorage["token"];
            user = sessionStorage["user"];
            if (token === undefined || token === '') {
                console.log("redirectingaction");
                this.props.history.push('/auth/login');
            }
        }

        if (user.length > 0)
            user = JSON.parse(user);
        let formData = new FormData();
        formData.append('userId', user.userId);

        var url = process.env.REACT_APP_API_URL;
        fetch(url + '/api/qrcodes/getallqrcodes', {
            method: "POST",
            body: formData, //JSON.stringify(formData),
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        }).then(response => {
            response.json().then(data => {
                //console.log(data);
                if (data.statusCode === 200 && data.actionCode === "QRCODE_FETCH_SUCCESSFUL") {
                    dispatch({
                        type: FETCH_ALL_QRCODES,
                        payload: data
                    });
                    //this.setState({shortCodeValidationClass: 'is-valid'});
                    //this.successAlert("Short Code is Valid!");
                }
                else {
                    dispatch({
                        type: ERROR_QRCODE,
                        payload: data
                    });
                    //this.setState({shortCodeValidationClass: 'is-invalid'});
                    //this.warningAlert("Short Code is already in use. Please try another...");
                }
            })
        }).catch(err => {
            dispatch({
                type: ERROR_QRCODE,
            })
            //this.warningAlert("An unknown error has occurred");
        });

    }
}


export function getQrCodeStats(requestBody) {
    return function (dispatch, getState) {
        var url = process.env.REACT_APP_API_URL;
        var rememberMe = localStorage["rememberMe"];
        var token = getState().userReducer.token;
        if ((token === undefined || token === '') && rememberMe === true) {
            token = localStorage["token"];
            if (token === undefined || token === '') {
                console.log("redirectingaction");
                this.props.history.push('/auth/login');
            }
        }
        else if (token === undefined || token === '') {
            token = sessionStorage["token"];
            if (token === undefined || token === '') {
                console.log("redirectingaction");
                this.props.history.push('/auth/login');
            }
        }
        fetch(url + '/api/qrcodes/getallqrcodestats', {
            method: "POST",
            body: requestBody,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
                //'Content-Type': 'application/json'
            },
        }).then(response => {
            response.json().then(data => {
                //console.log(data);
                if (data.statusCode === 200 && data.actionCode === "QRCODE_STATS_SUCCESSFUL") {
                    dispatch({
                        type: QRCODE_STATS,
                        payload: data
                    });
                }
                else {
                    dispatch({
                        type: ERROR_QRCODE,
                        payload: data
                    });
                }
            })
        }).catch(err => {
            dispatch({
                type: ERROR_QRCODE,
            })
        });
    }
}