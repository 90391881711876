import { LOGIN_USER, ERROR_LOGIN_USER, FORGOT_PASSWORD, ERROR_FORGOT_PASSWORD } from "./types";

export function loginUser(formData) {
    return function(dispatch) {
        var url = process.env.REACT_APP_API_URL;
        //const qr = getState().qrcodes.qr;
        //console.log(qr);

        fetch(url + '/api/user/login', {
        method: "POST",
        //credentials: 'include',
        body: formData,//JSON.stringify(requestData),
        headers: {
            'Accept': 'application/json'
            //'Content-Type': 'application/json'
        },
        }).then(response => {
            response.json().then(data => {
                if(data.statusCode === 200) {
                    dispatch({
                        type: LOGIN_USER,
                        payload: data
                    });
                }
                else if(data.statusCode === 400) {
                    dispatch({
                        type: ERROR_LOGIN_USER,
                        payload: data
                    });
                }
                else if(data.statusCode === 500) {
                    dispatch({
                        type: ERROR_LOGIN_USER,
                        payload: data
                    });
                }
                else {
                    dispatch({
                        type: ERROR_LOGIN_USER,
                        payload: data
                    });
                }
            })
        });
    }
}

export function forgotPassword(formData) {
    return function(dispatch) {
        var url = process.env.REACT_APP_API_URL;
        //const qr = getState().qrcodes.qr;
        //console.log(qr);

        fetch(url + '/api/user/forgotpassword', {
        method: "POST",
        //credentials: 'include',
        body: formData,//JSON.stringify(requestData),
        headers: {
            'Accept': 'application/json'
            //'Content-Type': 'application/json'
        },
        }).then(response => {
            response.json().then(data => {
                if(data.statusCode === 200) {
                    dispatch({
                        type: FORGOT_PASSWORD,
                        payload: data
                    });
                }
                else if(data.statusCode === 400) {
                    dispatch({
                        type: ERROR_FORGOT_PASSWORD,
                        payload: data
                    });
                }
                else if(data.statusCode === 500) {
                    dispatch({
                        type: ERROR_FORGOT_PASSWORD,
                        payload: data
                    });
                }
                else {
                    dispatch({
                        type: ERROR_FORGOT_PASSWORD,
                        payload: data
                    });
                }
            })
        });
    }
}