export const FETCH_ALL_QRCODES = 'FETCH_ALL_QRCODES';
export const NEW_QRCODE = 'NEW_QRCODE';
export const QRCODE_STATS = 'QRCODE_STATS';
export const ERROR_QRCODE = 'ERROR_QRCODE';

export const LOGIN_USER = "LOGIN_USER";
//export const REGISTER_USER = "REGISTER_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const ERROR_FORGOT_PASSWORD = "ERROR_FORGOT_PASSWORD";
export const ERROR_LOGIN_USER = "ERROR_LOGIN_USER";
//export const ERROR_REGISTER_USER = "ERROR_REGISTER_USER";