/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from 'react-redux';
import { getQrCodeStats } from '../../../actions/qrcodeActions';
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar, Pie } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import { VectorMap } from "react-jvectormap";
// core components
import UserCardsHeader from "../../../components/Headers/User/UserCardsHeader";
import DashboardDetailTable from "../../pages/components/DashboardDetailTable";
import { formatDate, addMonth } from '../../../utils/helper';
import {
  chartOptions,
  parseOptions,
  createUserClickChartForAll,
  createTotalClicksByMonthForPie
} from "variables/charts.jsx";

class Dashboard extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    qrCodes: [],
    qrdata: [],
    dataMonth: 1
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    if (this.state.activeNav !== index) {
      this.setState({
        activeNav: index,
        chartExample1Data: "data" + index
        //this.state.chartExample1Data === "data1" ? "data2" : "data1"
      });
    }

  };

  handleMonthChange = (monthCount) => {
    let fields = this.state;
    fields["filterStatsEndDate"] = new Date();
    fields["filterStatsStartDate"] = addMonth(new Date(), -monthCount);
    this.setState({ fields, dataMonth: monthCount });
    this.getAllQrCodeStatsByMonth(monthCount);
  }

  getAllQrCodeStatsByMonth = (month) => {

    let end_date = new Date();
    let start_date = addMonth(end_date, -month);
    let startDateStr = formatDate(start_date);
    let endDateStr = formatDate(end_date);
    let formData = new FormData();
    formData.append('startDate', startDateStr);
    formData.append('endDate', endDateStr);
    this.props.getQrCodeStats(formData);
  }

  getAllQrCodeStats() {

    let end_date = new Date();
    let start_date = addMonth(end_date, -6);
    let startDateStr = formatDate(start_date);
    let endDateStr = formatDate(end_date);
    let formData = new FormData();
    formData.append('startDate', startDateStr);
    formData.append('endDate', endDateStr);
    this.props.getQrCodeStats(formData);
  }

  componentWillMount() {
    var rememberMe = localStorage["rememberMe"];
    if (rememberMe === true) {
      var token = localStorage["token"];
      var user = localStorage["user"];

      if (token === undefined || token === '' || user === undefined || user === '') {
        console.log("redirecting80");
        window.location = "/auth/login"
      }
    }
    else {
      token = sessionStorage["token"];
      user = sessionStorage["user"];

      if (token === undefined || token === '' || user === undefined || user === '') {
        console.log("redirecting");
        window.location = "/auth/login"
      }
    }

    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
    //this.getAllQrCodeStats()

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.qrcodestats !== undefined && nextProps.qrcodestats.data !== undefined) {
      let qrdata = [];
      let qrcodes = nextProps.qrcodestats.data.map(qrcode => {
        qrdata.push(qrcode);
        return [];
      });

      if (JSON.stringify(qrdata) !== JSON.stringify(this.state.qrdata))
        this.setState({ qrdata: qrdata });
    }
  }

  render() {
    let userClickChart = createUserClickChartForAll(this.state.qrdata);
    let userClickPieChart = createTotalClicksByMonthForPie(this.state.qrdata);
    let countryStats = [];
    let refererStats = [];
    let totalClicks = 0;
    if (this.state.qrdata !== null && this.state.qrdata !== undefined) {
      for (let i = 0; i < this.state.qrdata.length; i++) {
        let qr = this.state.qrdata[i];
        let statsByCountry = qr.item2.statsByCountry;
        let statsByReferer = qr.item2.statsByReferer;
        if (statsByCountry !== null && statsByCountry !== undefined) {
          for (let j = 0; j < statsByCountry.length; j++) {
            let stat = statsByCountry[j];

            if (countryStats[stat.label] === undefined) {
              countryStats[stat.label] = { x: stat.label, y: stat.clicks, z: stat.country };
            }
            else {
              countryStats[stat.label].y += stat.clicks;
            }
          }
        }

        if (statsByReferer !== null && statsByReferer !== undefined) {
          for (let j = 0; j < statsByReferer.length; j++) {
            let stat = statsByReferer[j];
            totalClicks += stat.clicks;
            if (refererStats[stat.label] === undefined) {
              refererStats[stat.label] = { x: stat.label, y: stat.clicks };
            }
            else {
              refererStats[stat.label].y += stat.clicks;
            }
          }
        }

      }
    }

    let markers = [];
    let mapData = {
      // "AU": 760,
    };

    let labels = [];
    let keys = Object.keys(countryStats)
    let data = [];
    for (let i = 0; i < keys.length; i++) {
      let sdata = countryStats[keys[i]];
      mapData[sdata.x] = sdata.y;
      if (sdata.z !== null) {
        let marker = {};
        marker.latLng = [sdata.z.latitude, sdata.z.longitude];
        marker.name = sdata.z.geounit + ": " + sdata.y;
        markers.push(marker);
      }
    }
    labels.reverse();
    data.reverse();

    //referrer data points
    let rkeys = Object.keys(refererStats)
    let rtbody;
    let rtb = []
    let rothers = 0;
    for (let r = 0; r < rkeys.length; r++) {
      let sdata = refererStats[rkeys[r]];
      if (sdata.x.indexOf("facebook.") !== -1) {
        let perc = Math.round(sdata.y * 100 / totalClicks);
        rtbody =
          (
            <tr>
              <th scope="row">Facebook</th>
              <td>{sdata.y}</td>
              <td>
                <div className="d-flex align-items-center">
                  <span className="mr-2">{perc < 10 ? "0" : ""}{perc}%</span>
                  <div>
                    <Progress
                      max="100"
                      value={perc}
                      color="btn-facebook"
                    />
                  </div>
                </div>
              </td>
            </tr>);
        rtb.push(rtbody);
      }
      else if (sdata.x.indexOf("google.") !== -1) {
        let perc = Math.round(sdata.y * 100 / totalClicks);
        rtbody =
          (
            <tr>
              <th scope="row">Google</th>
              <td>{sdata.y}</td>
              <td>
                <div className="d-flex align-items-center">
                  <span className="mr-2">{perc}%</span>
                  <div>
                    <Progress
                      max="100"
                      value={perc}
                      color="btn-google"
                    />
                  </div>
                </div>
              </td>
            </tr>);
        rtb.push(rtbody);
      }
      else if (sdata.x.indexOf("instagram.") !== -1) {
        let perc = Math.round(sdata.y * 100 / totalClicks);
        rtbody =
          (
            <tr>
              <th scope="row">Instagram</th>
              <td>{sdata.y}</td>
              <td>
                <div className="d-flex align-items-center">
                  <span className="mr-2">{perc}%</span>
                  <div>
                    <Progress
                      max="100"
                      value={perc}
                      color="gradient-default"
                    />
                  </div>
                </div>
              </td>
            </tr>);
        rtb.push(rtbody);
      }
      else if (sdata.x.indexOf("twitter.") !== -1) {
        let perc = Math.round(sdata.y * 100 / totalClicks);
        rtbody =
          (
            <tr>
              <th scope="row">Twitter</th>
              <td>{sdata.y}</td>
              <td>
                <div className="d-flex align-items-center">
                  <span className="mr-2">{perc}%</span>
                  <div>
                    <Progress
                      max="100"
                      value={perc}
                      color="btn-twitter"
                    />
                  </div>
                </div>
              </td>
            </tr>);
        rtb.push(rtbody);
      }
      else if (sdata.x.indexOf("pinterest.") !== -1) {
        let perc = Math.round(sdata.y * 100 / totalClicks);
        rtbody =
          (
            <tr>
              <th scope="row">Pinterest</th>
              <td>{sdata.y}</td>
              <td>
                <div className="d-flex align-items-center">
                  <span className="mr-2">{perc}%</span>
                  <div>
                    <Progress
                      max="100"
                      value={perc}
                      color="btn-pinterest"
                    />
                  </div>
                </div>
              </td>
            </tr>);
        rtb.push(rtbody);
      }
      else if (sdata.x.indexOf("youtube.") !== -1) {
        let perc = Math.round(sdata.y * 100 / totalClicks);
        rtbody =
          (
            <tr>
              <th scope="row">Youtube</th>
              <td>{sdata.y}</td>
              <td>
                <div className="d-flex align-items-center">
                  <span className="mr-2">{perc}%</span>
                  <div>
                    <Progress
                      max="100"
                      value={perc}
                      color="btn-youtube"
                    />
                  </div>
                </div>
              </td>
            </tr>);
        rtb.push(rtbody);
      }
      else if (sdata.x.indexOf("vimeo.") !== -1) {
        let perc = Math.round(sdata.y * 100 / totalClicks);
        rtbody =
          (
            <tr>
              <th scope="row">Vimeo</th>
              <td>{sdata.y}</td>
              <td>
                <div className="d-flex align-items-center">
                  <span className="mr-2">{perc}%</span>
                  <div>
                    <Progress
                      max="100"
                      value={perc}
                      color="gradient-info"
                    />
                  </div>
                </div>
              </td>
            </tr>);
        rtb.push(rtbody);
      }
      else if (sdata.x.indexOf("slack.") !== -1) {
        let perc = Math.round(sdata.y * 100 / totalClicks);
        rtbody =
          (
            <tr>
              <th scope="row">Slack</th>
              <td>{sdata.y}</td>
              <td>
                <div className="d-flex align-items-center">
                  <span className="mr-2">{perc}%</span>
                  <div>
                    <Progress
                      max="100"
                      value={perc}
                      color="gradient-primary"
                    />
                  </div>
                </div>
              </td>
            </tr>);
        rtb.push(rtbody);
      }
      else if (sdata.x.indexOf("Direct") !== -1) {
        let perc = Math.round(sdata.y * 100 / totalClicks);
        rtbody =
          (
            <tr>
              <th scope="row">Direct</th>
              <td>{sdata.y}</td>
              <td>
                <div className="d-flex align-items-center">
                  <span className="mr-2">{perc}%</span>
                  <div>
                    <Progress
                      max="100"
                      value={perc}
                      color="gradient-info"
                    />
                  </div>
                </div>
              </td>
            </tr>);
        rtb.push(rtbody);
      }
      else {
        rothers += sdata.y;
      }
    }



    if (totalClicks > 0) {
      let perc = Math.round(rothers * 100 / totalClicks);
      if (isNaN(perc))
        perc = 0;
      rtbody =
        (
          <tr>
            <th scope="row">Others</th>
            <td>{rothers}</td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{perc}%</span>
                <div>
                  <Progress
                    max="100"
                    value={perc}
                    color="gradient-warning"
                  />
                </div>
              </div>
            </td>
          </tr>);
      rtb.push(rtbody);
    }
    // for(var i=0; i<countriesCount; i++) {
    //   mapData[this.props.stats.statsByCountry[i].label] = this.props.stats.statsByCountry[i].clicks;
    // }

    //console.log(userClickChart);

    return (
      <>
        <UserCardsHeader name="Default" parentName="Dashboards" qrdata={this.state.qrdata} monthChange={this.handleMonthChange} currMonth={this.state.dataMonth} />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="8">
              <Card className="bg-default">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-light text-uppercase ls-1 mb-1">
                        Overview
                      </h6>
                      <h5 className="h3 text-white mb-0">Total Clicks by Month</h5>
                    </div>
                    {/* <div className="col">
                      <Nav className="justify-content-end" pills>
                        <NavItem className="mr-2 mr-md-0">
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 1
                            })}
                            href="#"
                            onClick={e => this.toggleNavs(e, 1)}
                          >
                            <span className="d-none d-md-block">Month</span>
                            <span className="d-md-none">M</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 2
                            })}
                            data-toggle="tab"
                            href="#"
                            onClick={e => this.toggleNavs(e, 2)}
                          >
                            <span className="d-none d-md-block">Country</span>
                            <span className="d-md-none">C</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 3
                            })}
                            data-toggle="tab"
                            href="#"
                            onClick={e => this.toggleNavs(e, 3)}
                          >
                            <span className="d-none d-md-block">Referer</span>
                            <span className="d-md-none">R</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div> */}
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line
                      data={userClickChart["data1"]}
                      options={userClickChart.options}
                      id="chart-sales-dark"
                      className="chart-canvas"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card>
                <CardHeader>
                  <h6 className="surtitle">Overview</h6>
                  <h5 className="h3 mb-0">Total Traffic by Month</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Pie
                      data={userClickPieChart.dataMonthStats}
                      options={userClickPieChart.options}
                      className="chart-canvas"
                      id="chart-pie"
                    />
                  </div>
                </CardBody>
              </Card>
              {/* <Card>
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                      <h5 className="h3 mb-0">Total orders</h5>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Bar
                      data={chartExample2.data}
                      options={chartExample2.options}
                      className="chart-canvas"
                      id="chart-bars"
                    />
                  </div>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
          <Row>
            <Col xl="8">
              <Card style={{ backgroundColor: '#11526b' }}>
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-light text-uppercase ls-1 mb-1">
                        Overview
                      </h6>
                      <h5 className="h3 text-white mb-0">Total Clicks by Country</h5>
                    </div>
                    {/* <div className="col">
                      <Nav className="justify-content-end" pills>
                        <NavItem className="mr-2 mr-md-0">
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 1
                            })}
                            href="#"
                            onClick={e => this.toggleNavs(e, 1)}
                          >
                            <span className="d-none d-md-block">Month</span>
                            <span className="d-md-none">M</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 2
                            })}
                            data-toggle="tab"
                            href="#"
                            onClick={e => this.toggleNavs(e, 2)}
                          >
                            <span className="d-none d-md-block">Country</span>
                            <span className="d-md-none">C</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 3
                            })}
                            data-toggle="tab"
                            href="#"
                            onClick={e => this.toggleNavs(e, 3)}
                          >
                            <span className="d-none d-md-block">Referer</span>
                            <span className="d-md-none">R</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div> */}
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line
                      data={userClickChart["data2"]}
                      options={userClickChart.options}
                      id="chart-sales-dark"
                      className="chart-canvas"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card>
                <CardHeader>
                  <h6 className="surtitle">Overview</h6>
                  <h5 className="h3 mb-0">Total Traffic by Country</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Pie
                      data={userClickPieChart.dataCountryStats}
                      options={userClickPieChart.options}
                      className="chart-canvas"
                      id="chart-pie"
                    />
                  </div>
                </CardBody>
              </Card>
              {/* <Card>
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                      <h5 className="h3 mb-0">Total orders</h5>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Bar
                      data={chartExample2.data}
                      options={chartExample2.options}
                      className="chart-canvas"
                      id="chart-bars"
                    />
                  </div>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
          <Row>
            <Col xl="8">
              <Card style={{ backgroundColor: '#0c436b' }}>
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-light text-uppercase ls-1 mb-1">
                        Overview
                      </h6>
                      <h5 className="h3 text-white mb-0">Total Clicks by Referrers</h5>
                    </div>
                    {/* <div className="col">
                      <Nav className="justify-content-end" pills>
                        <NavItem className="mr-2 mr-md-0">
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 1
                            })}
                            href="#"
                            onClick={e => this.toggleNavs(e, 1)}
                          >
                            <span className="d-none d-md-block">Month</span>
                            <span className="d-md-none">M</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 2
                            })}
                            data-toggle="tab"
                            href="#"
                            onClick={e => this.toggleNavs(e, 2)}
                          >
                            <span className="d-none d-md-block">Country</span>
                            <span className="d-md-none">C</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 3
                            })}
                            data-toggle="tab"
                            href="#"
                            onClick={e => this.toggleNavs(e, 3)}
                          >
                            <span className="d-none d-md-block">Referer</span>
                            <span className="d-md-none">R</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div> */}
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line
                      data={userClickChart["data3"]}
                      options={userClickChart.options}
                      id="chart-sales-dark"
                      className="chart-canvas"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card>
                <CardHeader>
                  <h6 className="surtitle">Overview</h6>
                  <h5 className="h3 mb-0">Total Traffic by Referer</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Pie
                      data={userClickPieChart.dataRefererStats}
                      options={userClickPieChart.options}
                      className="chart-canvas"
                      id="chart-pie"
                    />
                  </div>
                </CardBody>
              </Card>
              {/* <Card>
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                      <h5 className="h3 mb-0">Total orders</h5>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Bar
                      data={chartExample2.data}
                      options={chartExample2.options}
                      className="chart-canvas"
                      id="chart-bars"
                    />
                  </div>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
          <Row>
            <Col xl="8">
              <Card style={{ backgroundColor: '#1a6498' }}>
                <CardHeader>
                  <h6 className="surtitle">Overview</h6>
                  <h5 className="h3 mb-0">Total Traffic</h5>
                </CardHeader>
                <CardBody className="pt-0">
                  <VectorMap
                    containerClassName="vector-map"
                    containerStyle={{
                      width: "100%",
                      height: "500px"
                    }}
                    map={"world_mill"}
                    ref={"map"}
                    zoomOnScroll={false}
                    scaleColors={["#f00", "#0071A4"]}
                    normalizeFunction="polynomial"
                    hoverOpacity={0.7}
                    hoverColor={false}
                    backgroundColor="transparent"
                    markerLabelStyle={{
                      initial: {
                        'font-family': 'Verdana',
                        'font-size': '12',
                        'font-weight': 'bold',
                        cursor: 'default',
                        fill: 'black'
                      },
                      hover: {
                        cursor: 'pointer'
                      }
                    }}
                    regionStyle={{
                      initial: {
                        fill: "#e9ecef",
                        "fill-opacity": 0.8,
                        stroke: "none",
                        "stroke-width": 0,
                        "stroke-opacity": 1
                      },
                      hover: {
                        fill: "#dee2e6",
                        "fill-opacity": 0.8,
                        cursor: "pointer"
                      },
                      selected: {
                        fill: "yellow"
                      },
                      selectedHover: {}
                    }}
                    markerStyle={{
                      initial: {
                        fill: "#fb6340",
                        "stroke-width": 0
                      },
                      hover: {
                        fill: "#11cdef",
                        "stroke-width": 0
                      }
                    }}
                    markers={markers}
                    series={{
                      regions: [
                        {
                          values: mapData,
                          scale: ["#ced4da", "#adb5bd"],
                          normalizeFunction: "polynomial"
                        }
                      ]
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="surtitle">Overview</h6>
                      <h5 className="h3 mb-0">Social Traffic</h5>
                    </div>
                    {/* <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div> */}
                  </Row>
                </CardHeader>
                <CardBody className="p-0" style={{ height: 525 }}>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Referral</th>
                        <th scope="col">Clicks</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {rtb}

                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            {/* <Col xl="4">
              <Card>
                <CardHeader>
                  <h5 className="h3 mb-0">Team members</h5>
                </CardHeader>

                <CardBody>
                  <ListGroup className="list my--3" flush>
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <a
                            className="avatar rounded-circle"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/team-1.jpg")}
                            />
                          </a>
                        </Col>
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              John Michael
                            </a>
                          </h4>
                          <span className="text-success">●</span>{" "}
                          <small>Online</small>
                        </div>
                        <Col className="col-auto">
                          <Button color="primary" size="sm" type="button">
                            Add
                          </Button>
                          
                        </Col>
                      </Row>
                    </ListGroupItem>
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <a
                            className="avatar rounded-circle"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/team-2.jpg")}
                            />
                          </a>
                        </Col>
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              Alex Smith
                            </a>
                          </h4>
                          <span className="text-warning">●</span>{" "}
                          <small>In a meeting</small>
                        </div>
                        <Col className="col-auto">
                          <Button color="primary" size="sm" type="button">
                            Add
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <a
                            className="avatar rounded-circle"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/team-3.jpg")}
                            />
                          </a>
                        </Col>
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              Samantha Ivy
                            </a>
                          </h4>
                          <span className="text-danger">●</span>{" "}
                          <small>Offline</small>
                        </div>
                        <Col className="col-auto">
                          <Button color="primary" size="sm" type="button">
                            Add
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <a
                            className="avatar rounded-circle"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/team-4.jpg")}
                            />
                          </a>
                        </Col>
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              John Michael
                            </a>
                          </h4>
                          <span className="text-success">●</span>{" "}
                          <small>Online</small>
                        </div>
                        <Col className="col-auto">
                          <Button color="primary" size="sm" type="button">
                            Add
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                </CardBody>
              </Card>
            </Col> 
            <Col xl="4">
              <Card>
                <CardHeader>
                  <h5 className="h3 mb-0">To do list</h5>
                </CardHeader>

                <CardBody className="p-0">
                  <ListGroup data-toggle="checklist" flush>
                    <ListGroupItem className="checklist-entry flex-column align-items-start py-4 px-4">
                      <div className="checklist-item checklist-item-success checklist-item-checked">
                        <div className="checklist-info">
                          <h5 className="checklist-title mb-0">
                            Call with Dave
                          </h5>
                          <small>10:30 AM</small>
                        </div>
                        <div>
                          <div className="custom-control custom-checkbox custom-checkbox-success">
                            <input
                              className="custom-control-input"
                              defaultChecked
                              id="chk-todo-task-1"
                              type="checkbox"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="chk-todo-task-1"
                            />
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="checklist-entry flex-column align-items-start py-4 px-4">
                      <div className="checklist-item checklist-item-warning">
                        <div className="checklist-info">
                          <h5 className="checklist-title mb-0">
                            Lunch meeting
                          </h5>
                          <small>10:30 AM</small>
                        </div>
                        <div>
                          <div className="custom-control custom-checkbox custom-checkbox-warning">
                            <input
                              className="custom-control-input"
                              id="chk-todo-task-2"
                              type="checkbox"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="chk-todo-task-2"
                            />
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="checklist-entry flex-column align-items-start py-4 px-4">
                      <div className="checklist-item checklist-item-info">
                        <div className="checklist-info">
                          <h5 className="checklist-title mb-0">
                            Argon Dashboard Launch
                          </h5>
                          <small>10:30 AM</small>
                        </div>
                        <div>
                          <div className="custom-control custom-checkbox custom-checkbox-info">
                            <input
                              className="custom-control-input"
                              id="chk-todo-task-3"
                              type="checkbox"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="chk-todo-task-3"
                            />
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="checklist-entry flex-column align-items-start py-4 px-4">
                      <div className="checklist-item checklist-item-danger checklist-item-checked">
                        <div className="checklist-info">
                          <h5 className="checklist-title mb-0">
                            Winter Hackaton
                          </h5>
                          <small>10:30 AM</small>
                        </div>
                        <div>
                          <div className="custom-control custom-checkbox custom-checkbox-danger">
                            <input
                              className="custom-control-input"
                              defaultChecked
                              id="chk-todo-task-4"
                              type="checkbox"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="chk-todo-task-4"
                            />
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  </ListGroup>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card>
                <CardHeader>
                  <h5 className="h3 mb-0">Progress track</h5>
                </CardHeader>

                <CardBody>
                  <ListGroup className="list my--3" flush>
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <a
                            className="avatar rounded-circle"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/bootstrap.jpg")}
                            />
                          </a>
                        </Col>
                        <div className="col">
                          <h5>Argon Design System</h5>
                          <Progress
                            className="progress-xs mb-0"
                            color="orange"
                            max="100"
                            value="60"
                          />
                        </div>
                      </Row>
                    </ListGroupItem>
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <a
                            className="avatar rounded-circle"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/angular.jpg")}
                            />
                          </a>
                        </Col>
                        <div className="col">
                          <h5>Angular Now UI Kit PRO</h5>
                          <Progress
                            className="progress-xs mb-0"
                            color="success"
                            max="100"
                            value="100"
                          />
                        </div>
                      </Row>
                    </ListGroupItem>
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <a
                            className="avatar rounded-circle"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/sketch.jpg")}
                            />
                          </a>
                        </Col>
                        <div className="col">
                          <h5>Black Dashboard</h5>
                          <Progress
                            className="progress-xs mb-0"
                            color="danger"
                            max="100"
                            value="72"
                          />
                        </div>
                      </Row>
                    </ListGroupItem>
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <a
                            className="avatar rounded-circle"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/react.jpg")}
                            />
                          </a>
                        </Col>
                        <div className="col">
                          <h5>React Material Dashboard</h5>
                          <Progress
                            className="progress-xs mb-0"
                            color="info"
                            max="100"
                            value="90"
                          />
                        </div>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                </CardBody>
              </Card>
            </Col>*/}
          </Row>
          <Row>
            {/* <Col xl="5">
              <Card>
                <CardHeader>
                  <h5 className="h3 mb-0">Activity feed</h5>
                </CardHeader>
                <CardHeader className="d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar"
                        src={require("assets/img/theme/team-1.jpg")}
                      />
                    </a>
                    <div className="mx-3">
                      <a
                        className="text-dark font-weight-600 text-sm"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        John Snow
                      </a>
                      <small className="d-block text-muted">3 days ago</small>
                    </div>
                  </div>
                  <div className="text-right ml-auto">
                    <Button
                      className="btn-icon"
                      color="primary"
                      size="sm"
                      type="button"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span className="btn-inner--text">Follow</span>
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <p className="mb-4">
                    Personal profiles are the perfect way for you to grab their
                    attention and persuade recruiters to continue reading your
                    CV because you’re telling them from the off exactly why they
                    should hire you.
                  </p>
                  <img
                    alt="..."
                    className="img-fluid rounded"
                    src={require("assets/img/theme/img-1-1000x600.jpg")}
                  />
                  <Row className="align-items-center my-3 pb-3 border-bottom">
                    <Col sm="6">
                      <div className="icon-actions">
                        <a
                          className="like active"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="ni ni-like-2" />
                          <span className="text-muted">150</span>
                        </a>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <i className="ni ni-chat-round" />
                          <span className="text-muted">36</span>
                        </a>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <i className="ni ni-curved-next" />
                          <span className="text-muted">12</span>
                        </a>
                      </div>
                    </Col>
                    <Col className="d-none d-sm-block" sm="6">
                      <div className="d-flex align-items-center justify-content-sm-end">
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-xs rounded-circle"
                            href="#pablo"
                            id="tooltip36177092"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/team-1.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip36177092"
                          >
                            Jessica Rowland
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-xs rounded-circle"
                            href="#pablo"
                            id="tooltip857639221"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-2.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip857639221"
                          >
                            Audrey Love
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-xs rounded-circle"
                            href="#pablo"
                            id="tooltip260223080"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-3.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip260223080"
                          >
                            Michael Lewis
                          </UncontrolledTooltip>
                        </div>
                        <small className="pl-2 font-weight-bold">
                          and 30+ more
                        </small>
                      </div>
                    </Col>
                  </Row>

                  <div className="mb-1">
                    <Media className="media-comment">
                      <img
                        alt="..."
                        className="avatar avatar-lg media-comment-avatar rounded-circle"
                        src={require("assets/img/theme/team-1.jpg")}
                      />
                      <Media>
                        <div className="media-comment-text">
                          <h6 className="h5 mt-0">Michael Lewis</h6>
                          <p className="text-sm lh-160">
                            Cras sit amet nibh libero nulla vel metus
                            scelerisque ante sollicitudin. Cras purus odio
                            vestibulum in vulputate viverra turpis.
                          </p>
                          <div className="icon-actions">
                            <a
                              className="like active"
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              <i className="ni ni-like-2" />
                              <span className="text-muted">3 likes</span>
                            </a>
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              <i className="ni ni-curved-next" />
                              <span className="text-muted">2 shares</span>
                            </a>
                          </div>
                        </div>
                      </Media>
                    </Media>
                    <Media className="media-comment">
                      <img
                        alt="..."
                        className="avatar avatar-lg media-comment-avatar rounded-circle"
                        src={require("assets/img/theme/team-2.jpg")}
                      />
                      <Media>
                        <div className="media-comment-text">
                          <h6 className="h5 mt-0">Jessica Stones</h6>
                          <p className="text-sm lh-160">
                            Cras sit amet nibh libero, in gravida nulla. Nulla
                            vel metus scelerisque ante sollicitudin. Cras purus
                            odio, vestibulum in vulputate at, tempus viverra
                            turpis.
                          </p>
                          <div className="icon-actions">
                            <a
                              className="like active"
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              <i className="ni ni-like-2" />
                              <span className="text-muted">10 likes</span>
                            </a>
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              <i className="ni ni-curved-next" />
                              <span className="text-muted">1 share</span>
                            </a>
                          </div>
                        </div>
                      </Media>
                    </Media>
                    <hr />
                    <Media className="align-items-center">
                      <img
                        alt="..."
                        className="avatar avatar-lg rounded-circle mr-4"
                        src={require("assets/img/theme/team-3.jpg")}
                      />
                      <Media body>
                        <Form>
                          <Input
                            placeholder="Write your comment"
                            rows="1"
                            type="textarea"
                          />
                        </Form>
                      </Media>
                    </Media>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
            <Col xl="12">
              <Row>
                <div className="col">
                  <Card>
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <h6 className="surtitle">Overview</h6>
                          <h5 className="h3 mb-0">QR Codes</h5>
                        </div>
                      </Row>
                    </CardHeader>
                    <Table
                      className="align-items-center table-flush table-striped table-scroll"
                      responsive hover
                    // style={{overflowY: 'auto', maxHeight:500}}
                    >
                      <thead className="thead-light">
                        <tr
                        // style={{width: '100%', display: 'inline-table', tableLayout: 'fixed'}}
                        >
                          <th scope="col">QR Code</th>
                          <th scope="col">Long Url</th>
                          <th scope="col">Short Url</th>
                          <th scope="col">Clicks</th>
                          <th scope="col">Status</th>
                          <th scope="col">Stats</th>
                          <th scope="col">Tags</th>
                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody className="list"
                      // style={{overflowY: 'auto', maxHeight:500,position: 'absolute', background:'#fff'}}
                      >
                        <DashboardDetailTable qrData={this.state.qrdata} />
                        {/* <DashboardDetailTable qrData={this.state.qrdata} />
                        <DashboardDetailTable qrData={this.state.qrdata} />
                        <DashboardDetailTable qrData={this.state.qrdata} /> */}
                      </tbody>
                    </Table>
                  </Card>
                </div>
              </Row>
              {/* <div className="card-deck">
                <Card className="bg-gradient-default">
                  <CardBody>
                    <div className="mb-2">
                      <sup className="text-white">$</sup>{" "}
                      <span className="h2 text-white">3,300</span>
                      <div className="text-light mt-2 text-sm">
                        Your current balance
                      </div>
                      <div>
                        <span className="text-success font-weight-600">
                          + 15%
                        </span>{" "}
                        <span className="text-light">($250)</span>
                      </div>
                    </div>
                    <Button
                      block
                      className="btn-neutral"
                      color="default"
                      size="sm"
                    >
                      Add credit
                    </Button>
                  </CardBody>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <small className="text-light">Orders: 60%</small>
                        <Progress
                          className="progress-xs my-2"
                          max="100"
                          value="60"
                          color="success"
                        />
                      </div>
                      <div className="col">
                        <small className="text-light">Sales: 40%</small>
                        <Progress
                          className="progress-xs my-2"
                          max="100"
                          value="40"
                          color="warning"
                        />
                      </div>
                    </Row>
                  </CardBody>
                </Card>
                <Card className="bg-gradient-danger">
                  <CardBody>
                    <Row className="justify-content-between align-items-center">
                      <div className="col">
                        <img
                          alt="..."
                          src={require("assets/img/icons/cards/bitcoin.png")}
                        />
                      </div>
                      <Col className="col-auto">
                        <Badge className="badge-lg" color="success">
                          Active
                        </Badge>
                      </Col>
                    </Row>
                    <div className="my-4">
                      <span className="h6 surtitle text-light">Username</span>
                      <div className="h1 text-white">@johnsnow</div>
                    </div>
                    <Row>
                      <div className="col">
                        <span className="h6 surtitle text-light">Name</span>
                        <span className="d-block h3 text-white">John Snow</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </div> */}
            </Col>
          </Row>
          <Row>
            {/* <Col xl="8">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Page visits</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Page name</th>
                      <th scope="col">Visitors</th>
                      <th scope="col">Unique users</th>
                      <th scope="col">Bounce rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">/argon/</th>
                      <td>4,569</td>
                      <td>340</td>
                      <td>
                        <i className="fas fa-arrow-up text-success mr-3" />
                        46,53%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/index.html</th>
                      <td>3,985</td>
                      <td>319</td>
                      <td>
                        <i className="fas fa-arrow-down text-warning mr-3" />
                        46,53%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/charts.html</th>
                      <td>3,513</td>
                      <td>294</td>
                      <td>
                        <i className="fas fa-arrow-down text-warning mr-3" />
                        36,49%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/tables.html</th>
                      <td>2,050</td>
                      <td>147</td>
                      <td>
                        <i className="fas fa-arrow-up text-success mr-3" />
                        50,87%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/profile.html</th>
                      <td>1,795</td>
                      <td>190</td>
                      <td>
                        <i className="fas fa-arrow-down text-danger mr-3" />
                        46,53%
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col> */}

          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  //qrcodes: state.qrCodeReducer.items,
  qrcodestats: state.qrCodeReducer.stats
});

export default connect(mapStateToProps, { getQrCodeStats })(Dashboard);
